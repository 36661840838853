import { Modal } from "./Modal";
import { Avatar } from "src/ccl/blocks";
import { PortfolioImage } from "src/ccl/document/assetImage";
import { Icon } from "src/ccl/document/icon";
import { Box, Flex } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import { Text } from "src/ccl/document/text";
import { PortfolioImage as PortfolioImageType, User } from "src/graphql/types";
import { getInitials } from "src/utils/lang";
import { urlAddQuery } from "src/utils/url";

interface PortfolioImageModalProps {
  talent: User;
  portfolioImage: PortfolioImageType;
  isOpen: boolean;
  onClose: () => void;
}

export const PortfolioImageModal = ({
  talent,
  portfolioImage,
  isOpen,
  onClose,
}: PortfolioImageModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      showCloseButton
      onClose={onClose}
      width="super-wide"
      variant="dark"
    >
      <PortfolioImageModalContent
        talent={talent}
        portfolioImage={portfolioImage}
      />
    </Modal>
  );
};

export const PortfolioImageModalContent = ({
  talent,
  portfolioImage,
  context,
  onBack,
}: {
  talent: User;
  portfolioImage: PortfolioImageType;
  context?: "talentProfileModal";
  onBack?: () => void;
}) => {
  return (
    <>
      <Flex
        css={{
          borderBottom: "1px solid $grey6",
          flexDirection: "column",
          justifyContent: "initial",
          mt: "$3",
          pb: "$6",
          gap: "$5",
          "@bp2": {
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "$2",
            mt: "$8",
          },
        }}
      >
        <Flex css={{ alignImages: "center" }}>
          {context && (
            <Box css={{ mr: "$7" }} onClick={onBack}>
              <Icon
                variant="chevronLeft"
                size={18}
                color="white"
                css={{ cursor: "pointer" }}
              />
            </Box>
          )}
          <Avatar
            size="$largeAvatar"
            imageUrl={urlAddQuery(
              talent.featuredAsset?.mediaUrl,
              "w=70&crop=faces",
            )}
            css={{ mr: "$6", minWidth: "$largeAvatar" }}
            initials={getInitials(talent.name)}
          />
          <Text variant="h3" color="white">
            {talent.name}
          </Text>
        </Flex>

        <Button
          css={{
            display: "flex",
            alignImages: "center",
            alignSelf: "flex-start",
          }}
        >
          <Icon
            size={16}
            variant="plus"
            css={{
              mr: "$3",
              display: "inline-block",
            }}
          />
          Add to shortlist
        </Button>
      </Flex>

      <Box
        css={{
          mt: "$11",
          mb: "$14",
        }}
      >
        <PortfolioImage
          asset={portfolioImage}
          maxWidth={1200}
          alt={`A portfolio image From ${talent.name}`}
        />
      </Box>
    </>
  );
};
