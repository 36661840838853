import React, { useCallback } from "react";
import * as Stitches from "@stitches/react";
import {
  formatRange,
  useDateRangeFilterState,
} from "./useDateRangeFilterState";
import { DropdownPill, Pill } from "src/ccl/blocks";
import { DateRangeInput, JobDateRangeOption } from "src/graphql/types";
import { Icon, Text } from "src/ccl/document";
import { Flex, Box, Grid } from "src/ccl/layout";
import { RangePicker } from "src/ccl/data-entry";

export const DateRangeOption = ({
  selected,
  label,
  onClick,
}: {
  selected: boolean;
  label: string;
  onClick: () => void;
}) => (
  <Pill
    onClick={onClick}
    variant="roundedFilter"
    css={{
      backgroundColor: selected ? "$black" : undefined,
      borderColor: selected ? undefined : "$grey3",
      "&:hover": { cursor: "pointer" },
    }}
  >
    <Text
      css={{
        color: selected ? "$white" : "$grey4",
      }}
    >
      {label}
    </Text>
  </Pill>
);

export const DateRangeFilter = ({
  dateRange,
  onChange,
  label,
  datePickerHeaderText,
  css,
}: {
  dateRange?: DateRangeInput;
  onChange: (dateRange?: DateRangeInput) => void;
  label?: string;
  datePickerHeaderText?: string;
  css?: Stitches.CSS;
}) => {
  const setCustomRange = useCallback(
    (range: DateRangeInput) => onChange(formatRange(range)),
    [],
  );
  const [selected, buildRangeOptionProps] = useDateRangeFilterState(onChange);

  const datePickerLabel = label ?? "Date range";
  const datePickerLabelHeader = datePickerHeaderText ?? "Show jobs for:";

  return (
    <DropdownPill
      active={!!dateRange}
      text={datePickerLabel}
      icon="scatteredDays"
      css={css}
    >
      <Box css={{ pt: "$6", pb: "$10", px: "$16" }}>
        <Grid css={{ gridColumns: "1fr 1fr 1fr" }}>
          <Box />
          <Flex css={{ gap: "$2", pb: "$6" }}>
            <Icon
              variant="information"
              size={16}
              color="grey6"
              css={{ my: "auto" }}
            />
            <Text css={{ color: "$grey6" }}>{datePickerLabelHeader}</Text>
          </Flex>
        </Grid>
        <Flex css={{ gap: "$4" }}>
          <DateRangeOption
            label="This Week"
            {...buildRangeOptionProps(JobDateRangeOption.ThisWeek)}
          />
          <DateRangeOption
            label="Last 7 Days"
            {...buildRangeOptionProps(JobDateRangeOption.LastSevenDays)}
          />
          <DateRangeOption
            label="This Month"
            {...buildRangeOptionProps(JobDateRangeOption.ThisMonth)}
          />
        </Flex>
        <Flex css={{ gap: "$4" }}>
          <DateRangeOption
            label="Last 30 Days"
            {...buildRangeOptionProps(JobDateRangeOption.LastThirtyDays)}
          />
          <DateRangeOption
            label="Last Month"
            {...buildRangeOptionProps(JobDateRangeOption.LastMonth)}
          />
          <DateRangeOption
            label="Custom"
            {...buildRangeOptionProps(JobDateRangeOption.Custom)}
          />
        </Flex>
        {selected === JobDateRangeOption.Custom && (
          <RangePicker onChange={setCustomRange} />
        )}
      </Box>
    </DropdownPill>
  );
};
