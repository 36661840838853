import { JobTalent, JobTalentState } from "src/graphql/types";

export const jobTalentStateTitle = (state: JobTalentState): string => {
  return {
    ACCEPTED: "Available",
    AWAITING_CONFIRMATION: "Available",
    CONFIRMED: "Confirmed",
    DISPUTED: "In dispute",
    PAID: "Paid",
    PENDING: "Response pending",
    REJECTED: "Unavailable",
    REJECTED_BY_BOOKER: "Released",
    PENDING_AGENCY_APPROVAL: "Pending Agency Approval",
  }[state];
};

export const jobTalentWithState = (
  jobTalent: JobTalent[] | undefined,
  state: JobTalentState | JobTalentState[],
) => {
  if (jobTalent === undefined) {
    return [];
  }

  const states = Array.isArray(state) ? state : [state];

  return jobTalent.filter((jt) => states.some((state) => state === jt.state));
};
