import React, { useState } from "react";
import type * as Stitches from "@stitches/react";
import { Box, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { useStoreModel } from "src/hooks";
import { Button } from "src/ccl/navigation";
import { Toggle } from "src/ccl/data-entry";
import {
  NumericAttribute,
  TalentProfile,
  User,
  ConversionType,
} from "src/graphql/types";
import { formatMetricV2 } from "src/utils/measurementConversions";
import { mergeCss } from "src/ccl/stitches";

type Unit = "metric" | "imperial";
const attributeBoxesPerRow = 4;

const renderMeasurement = (attribute: NumericAttribute, unit: Unit) => {
  const { name, value, conversionType } = attribute;
  if (unit === "metric") {
    if (name.includes("shoe")) {
      return (
        <Text variant="impact">
          {formatMetricV2(value as number, ConversionType.EuShoe)}
        </Text>
      );
    }
    return (
      <Text variant="impact">
        {formatMetricV2(value as number, ConversionType.Centimeters)}
      </Text>
    );
  }

  if (unit === "imperial") {
    return (
      <Text variant="impact">
        {formatMetricV2(value as number, conversionType)}
      </Text>
    );
  }
};

const AttributeBox = ({
  attribute,
  index,
  unit,
}: {
  attribute: NumericAttribute;
  index: number;
  unit: "metric" | "imperial";
}) => (
  <Box
    css={{
      padding: "$3",
      borderBottom: "1px solid $black",
      borderRight: "1px solid $black",
      borderLeft: index % attributeBoxesPerRow === 0 ? "1px solid $black" : "",
      borderTop: index < attributeBoxesPerRow ? "1px solid $black" : "",
    }}
  >
    <Text
      variant="mini"
      css={{
        fontFamily: "$sansWide",
      }}
    >
      {attribute.name}
    </Text>
    {renderMeasurement(attribute, unit)}
  </Box>
);

interface TalentProfileMeasurementsProps {
  talent: User;
  css?: Stitches.CSS;
}

export const TalentProfileMeasurements = ({
  talent,
  css = {},
}: TalentProfileMeasurementsProps) => {
  const { measurementUnit } = useStoreModel("preferences");
  const attributes = (talent.profile as TalentProfile).numericAttributes
    ?.filter((a) => !!a.value)
    .map((a) => ({ ...a, name: a.name.split("_")[0] } as NumericAttribute));

  const [showMeasurements, setShowMeasurements] = useState<boolean>(false);

  return (
    <Box css={mergeCss({}, css)}>
      <Button
        css={{
          display: "flex",
          alignItems: "center",
          mb: showMeasurements ? "$5" : "initial",
        }}
        onClick={() => setShowMeasurements(!showMeasurements)}
      >
        <Icon
          size={16}
          variant={showMeasurements ? "chevronUp" : "chevronDown"}
          css={{ mr: "$3" }}
        />
        {showMeasurements ? "Hide measurements" : "View measurements"}
      </Button>
      <Box css={{ display: showMeasurements ? "initial" : "none" }}>
        <Toggle
          id="measurementsToggle"
          textUnchecked="Metric"
          textChecked="Imperial"
          checked={measurementUnit.preference === "imperial"}
          onCheck={() => {
            measurementUnit.setPreference("imperial");
          }}
          onUncheck={() => {
            measurementUnit.setPreference("metric");
          }}
          uncheckedGreyBackground
        />
        <Grid
          css={{
            gridColumns: attributeBoxesPerRow,
            mt: "$3",
          }}
        >
          {attributes &&
            attributes.map((attr, i) => (
              <AttributeBox
                attribute={attr}
                key={i}
                index={i}
                unit={measurementUnit.preference}
              />
            ))}
        </Grid>
      </Box>
    </Box>
  );
};
