import React from "react";
import { useFormikContext } from "formik";
import { TalentFormValues } from "./formConfigV2";
import { Field, FormikInput, SelectReact } from "src/ccl/data-entry";
import { Box, Grid } from "src/ccl/layout";
import countries from "src/data/countries.json";
import { Text } from "src/ccl/document";
import { useFeatures } from "src/hooks";
import { SectionHeader } from "src/components/SectionHeader";
import { ProfileFormContext } from "src/graphql/types";

interface PaymentDetailsProps {
  values: TalentFormValues;
  context: ProfileFormContext;
}

export const PaymentDetails = ({ values, context }: PaymentDetailsProps) => {
  const { featureEnabled } = useFeatures();
  const rebrandFlag = featureEnabled("tpa_updated_creative_upload");
  const variant = rebrandFlag ? "rebrand" : undefined;
  const { setFieldValue } = useFormikContext();
  return (
    <Box data-step-header-section css={{ mt: "42px" }}>
      <SectionHeader
        step={context === "create" ? "3" : undefined}
        label="Payment details"
      />
      <Text variant="h4" css={{ mb: "$8" }}>
        Day rate
      </Text>
      <Grid
        css={{
          gridColumns: 1,
          mt: "$6",
          columnGap: "$8",
          "@bp4": {
            gridColumns: 2,
          },
        }}
      >
        <Field
          variant="b2Bold"
          name="talentProfile.minDayRate"
          label="Min Day Rate"
          secondaryLabel="(optional)"
          labelCss={{ mb: "$5" }}
        >
          <FormikInput
            name="talentProfile.minDayRate"
            type="number"
            variant={variant}
          />
        </Field>
        <Field
          variant="b2Bold"
          name="talentProfile.maxDayRate"
          label="Max Day Rate"
          secondaryLabel="(optional)"
          labelCss={{ mb: "$5" }}
        >
          <FormikInput
            name="talentProfile.maxDayRate"
            type="number"
            variant={variant}
          />
        </Field>
      </Grid>
      <Text variant="h4" css={{ mb: "$8" }}>
        Billing address
      </Text>
      <Field
        variant="b2Bold"
        name="addressLine1"
        label="Line 1"
        labelCss={{ mb: "$5" }}
      >
        <FormikInput name="addressLine1" type="text" variant={variant} />
      </Field>
      <Field
        variant="b2Bold"
        name="addressLine2"
        label="Line 2"
        secondaryLabel="(optional)"
        labelCss={{ mb: "$5" }}
      >
        <FormikInput name="addressLine2" type="text" variant={variant} />
      </Field>
      <Field
        variant="b2Bold"
        name="city"
        label="Town / City"
        labelCss={{ mb: "$5" }}
      >
        <FormikInput name="city" type="text" variant={variant} />
      </Field>
      <Field
        variant="b2Bold"
        name="postcode"
        label="Postcode"
        labelCss={{ mb: "$5" }}
      >
        <FormikInput name="postcode" type="text" variant={variant} />
      </Field>
      <Field
        variant="b2Bold"
        name="country"
        label="Country"
        css={{ mb: "$8" }}
        labelCss={{ mb: "$5" }}
      >
        <SelectReact
          id="country"
          variant={variant}
          initialValues={countries["countries"].find(
            (country) => country.value === values.country,
          )}
          onChange={(country) => setFieldValue("country", country?.value)}
          options={countries["countries"].sort((a, b) =>
            a.label.localeCompare(b.label),
          )}
          isSearchable
        />
      </Field>
    </Box>
  );
};
