import React, { useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { ProfileDropdown, BurgerPanel, SideNavBar } from "src/ccl/blocks";
import { Icon, Logo, Text } from "src/ccl/document";
import { Container, Box, Flex } from "src/ccl/layout";
import { Link, LinkButton, SupportLauncher } from "src/ccl/navigation";
import { ShortlistHeaderButton } from "src/components";
import {
  useFeatures,
  useStoreModel,
  useToggle,
  useUserKindContext,
} from "src/hooks";
import { styled } from "src/ccl/stitches";
import {
  useScrollDirection,
  ScrollDirection,
} from "src/hooks/useScrollDirection";
import { sendToAmplitude } from "src/utils/analytics";

const MobileNavigationPaneLauncher = styled("a", {
  display: "flex",
  alignContent: "center",
  marginLeft: "$2",
  cursor: "pointer",

  "@md": {
    display: "none",
  },
});

export const DashboardHeader = () => {
  const { path } = useRouteMatch();
  const scrollDirection = useScrollDirection();
  const currentUser = useStoreModel("currentUser");
  const context = useUserKindContext(currentUser);
  const { avatarInitials, me } = currentUser;
  const { featureEnabled } = useFeatures();

  const headerRef = useRef<HTMLDivElement>(null);

  const jobsContext = context === "client" || context === "signedOut";
  const isSticky =
    (jobsContext && path.includes("/jobs/new/shortlist")) ||
    path.includes("/agency");

  const onSignOut = () => {
    currentUser.logOut();
  };

  const [navigationPaneOpen, toggleNavigationPaneOpen] = useToggle();
  const navigateTo = () => {
    let link = `/dashboard/${context}/jobs`;
    if (context === "signedOut") {
      link = "/";
    }

    if (context === "TPA") {
      link = "/dashboard/agent/jobs";
    }

    return link;
  };

  return (
    <Container
      data-test-id="Header"
      ref={headerRef}
      css={{
        justifyContent: "space-between",
        alignItems: "center",
        py: "$4",
        position: isSticky ? "sticky" : "initial",
        top:
          scrollDirection === ScrollDirection.down
            ? (headerRef.current?.offsetHeight || 0) * -1
            : 0,
        transition: "0.5s ease all",
        zIndex: "$500",
        background: "$white",

        "@md": {
          py: "$3",
        },

        "@lg": {
          py: "$5",
        },
      }}
    >
      <Link
        data-test-id="HeaderLogo"
        to={navigateTo()}
        onClick={() => {
          sendToAmplitude("top nav - clicks contact home button");
        }}
      >
        <Box css={{ display: "none", "@lg": { display: "block" } }}>
          <Logo variant="full" />
        </Box>
        <Box css={{ display: "block", "@lg": { display: "none" } }}>
          <Logo variant="mark" />
        </Box>
      </Link>
      <Flex
        css={{
          alignItems: "center",
          gap: "$6",

          "@lg": {
            gap: "$9",
          },
        }}
      >
        <SupportLauncher
          data-test-id="HeaderNeedHelp"
          longContent="Help"
          iconSize={16}
          color="black"
          css={{
            display: "none",
            "@bp2": { display: "block" },
            "@bp3": { display: "none" },
          }}
          longContentCSS={{
            "&:hover": { color: "$grey6" },
          }}
          iconCss={{
            display: "block",
            width: "24px",
            height: "24px",
            "@bp3": {
              width: "16px",
              height: "16px",
            },
          }}
          wrapperCSS={{
            "&:hover": {
              color: "$grey6",
              "svg path": { fill: "$grey6" },
            },
          }}
        />
        {featureEnabled("messaging") && (
          <LinkButton
            to={`/dashboard/${context === "TPA" ? "agent" : context}/messages`}
            variant="link"
            css={{
              "&:hover": {
                color: "$grey6",
                "svg path": { fill: "$grey6" },
              },
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              size={16}
              variant="chatBubble"
              color="black"
              css={{
                display: "none",
                "@bp4": { display: "block" },
                "@bp3": { display: "none" },
                "@bp2": { display: "block" },
              }}
            />
            <Text
              variant="mini"
              color="black"
              css={{
                display: "none",
                marginLeft: "$2",
                "@bp3": { display: "block" },
                "&:hover": { color: "$grey6" },
              }}
            >
              Messages
            </Text>
          </LinkButton>
        )}
        <LinkButton
          to="/dashboard"
          variant="tertiaryDark"
          css={{
            backgroundColor: "$grey5",
            display: "none",

            "@md": {
              display: "initial",
            },
          }}
        >
          Dashboard
        </LinkButton>

        <ProfileDropdown
          avatarImageUrl={me?.featuredAsset?.mediaUrl}
          avatarInitials={avatarInitials}
          onSignOut={onSignOut}
          userName={me?.name}
          userEmail={me?.email}
          context={context}
          isAgent={currentUser?.isAgent}
        />

        {jobsContext && <ShortlistHeaderButton ref={headerRef} />}

        <MobileNavigationPaneLauncher
          data-test-id="DashboardHeaderBurger"
          onClick={(e) => {
            e.preventDefault();

            toggleNavigationPaneOpen();
          }}
        >
          <Icon variant="menu" size={28} />
        </MobileNavigationPaneLauncher>
        <BurgerPanel
          open={navigationPaneOpen}
          onClose={toggleNavigationPaneOpen}
          onSignOut={() => {
            onSignOut && onSignOut();
            toggleNavigationPaneOpen();
          }}
          burgerBar={
            <SideNavBar
              path={path}
              onClick={() => {
                toggleNavigationPaneOpen();
              }}
            />
          }
        />
      </Flex>
    </Container>
  );
};
