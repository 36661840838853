import React, { useState } from "react";
import { Avatar } from "src/ccl/blocks";
import { Icon, Review, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { BookmarkAndCollectionCircle } from "src/components/dashboards/booker/bookmarks";
import { TalentProfile, User } from "src/graphql/types";
import { getInitials } from "src/utils/lang";

export const MainDetails = ({
  talent,
  handleBookmark,
  isBookmarkDisabled,
  onAddToCollection,
}: {
  talent: User;
  handleBookmark?: (isChecked: boolean, talentId: string) => void;
  isBookmarkDisabled?: boolean;
  onAddToCollection?: (talent: User) => void;
}) => {
  const profile = talent.profile as TalentProfile;
  const { primaryLocation, totalReviews, averageRating } = profile;
  const [checked, setChecked] = useState(!!talent.bookmarked);
  return (
    <Flex
      css={{
        justifyContent: "space-between",
      }}
    >
      <Flex css={{ alignItems: "center" }}>
        <Avatar
          css={{
            flexShrink: 0,
            width: "$avatar",
            height: "$avatar",
            mr: "$4",
          }}
          initials={getInitials(talent.name)}
          imageUrl={talent.featuredAsset?.mediaUrl}
        />
        <Box>
          <Text
            variant="b1Bold"
            css={{
              flex: 1,
              mr: "$4",
              fontWeight: "$bold",
            }}
          >
            {talent.name}
          </Text>
          <Flex
            css={{
              alignItems: "center",
              gap: "$2",
              mt: "$2",
            }}
          >
            <Flex css={{ alignItems: "center" }}>
              <Icon variant="onsite" size={14} css={{ mr: "$2" }} />
              <Text
                css={{
                  display: "none",
                  "@bp2": {
                    display: "inherit",
                  },
                }}
              >
                {primaryLocation.name}
              </Text>
            </Flex>
            {averageRating && (
              <Review
                textVariant="impact"
                average={averageRating}
                total={totalReviews}
                css={{ marginLeft: "$6" }}
              />
            )}
          </Flex>
        </Box>
      </Flex>
      {handleBookmark && (
        <Box css={{ width: "36px", height: "36px" }}>
          <BookmarkAndCollectionCircle
            onAddToCollection={
              onAddToCollection ? () => onAddToCollection(talent) : undefined
            }
            withTooltip={false}
            checked={checked}
            onClick={(e) => {
              handleBookmark((e.target as HTMLInputElement).checked, talent.id);
              if (isBookmarkDisabled) {
                e.preventDefault();
              } else {
                setChecked(!checked);
              }
            }}
            // React throws errors about a read-only form if
            // there is no onChange prop supplied to an input field
            onChange={() => {}}
            data-test-id="TalentModalBookmark"
            slideOutLeft={true}
            containerCss={{ position: "relative" }}
          />
        </Box>
      )}
    </Flex>
  );
};
