import React from "react";
import { useHistory } from "react-router-dom";
import { CSS } from "@stitches/react";
import { mergeCss, ResponsiveValue, styled } from "src/ccl/stitches";
import { Checkbox } from "src/ccl/data-entry";
import { AssetImage, Icon, Text } from "src/ccl/document";
import { Button, ExternalLink } from "src/ccl/navigation";
import { AssetImageSize } from "src/ccl/document/assetImage/types";
import { TalentProfile, User } from "src/graphql/generated/types";
import { Flex, Grid } from "src/ccl/layout";
import { uppercaseFirstLetter } from "src/utils/lang";
import { pronounsMap, verticalIconMapAlt, verticalMap } from "src/utils/user";
import { ReviewForm } from "src/ccl/document/review";
import { useFeatures } from "src/hooks";

export interface TalentListItemProps {
  talent: User;
  getSubHeading?: (talent: User) => React.ReactNode;
  isRemovable?: (talent: User) => boolean;
  onRemove?: (talent: User) => void;
  removeText?: string;
  isCheckable?: (talent: User) => boolean;
  isChecked?: (talent: User) => boolean;
  onCheck?: (talent: User, checked: boolean) => void;
  getLink?: (talent: User) => string;
  avatarSizes?: ResponsiveValue<AssetImageSize>;
  avatarCss?: CSS;
  showEmailLink?: boolean;
  showEmailSubheader?: boolean;
  ccEmail?: string;
  showContactData?: boolean;
  onClick?: (talent: User) => void;
  url?: (slug: string) => string;
  alignItems?: "start" | "center";
  showTalentDescriptorText?: boolean;
  variant?: "boxed" | "noMargin";
  openInNewWindow?: boolean;
  disabled?: boolean;
  checkboxSize?: ResponsiveValue<{ width: number; height: number }>;
  showVertical?: boolean;
  onReview?: (talent: User, rating: number) => void;
  isRemoveText?: boolean;
}

const LinkWrapperContainer = styled("div", {
  cursor: "pointer",
  display: "block",
  width: "100%",
});

const LinkWrapperInner = styled("div", {
  display: "flex",
  width: "100%",

  variants: {
    alignItems: {
      start: {
        alignItems: "start",
      },
      center: {
        alignItems: "center",
      },
    },
  },
});

const TalentListItemContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  pr: "$2",
  breakInside: "avoid",
  mb: "$3",
  "@bp2": {
    mb: "$5",
  },
  variants: {
    variant: {
      boxed: {
        borderTop: "solid",
        borderBottom: "solid",
        borderTopColor: "$grey3",
        borderBottomColor: "$grey3",
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        pt: "$3",
        pb: "$3",
        mb: "-1px", // overlap bottom borders between cards
        "@bp2": {
          mb: "-1px", // overlap bottom borders between cards
          pt: "$5",
          pb: "$5",
        },
      },
      noMargin: {
        m: "0px !important",
      },
    },
  },
});

const TalentDetails = styled("div", {
  my: "auto",
  mr: "auto",
  ml: "$3",
  "@bp2": {
    ml: "$5",
  },
});

const EmailLinkContainer = styled("div", {
  alignSelf: "flex-end",
  ml: "auto",
});

const EmailLink = styled(ExternalLink, {
  fontSize: "$14",
  color: "$grey6",
});

const RemoveButton = styled(Button, {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  paddingLeft: "$2",
  outline: "none",
  cursor: "pointer",
});

const EmailSubheader = ({
  ccEmail,
  name,
  emailAddress,
}: {
  ccEmail?: string;
  name: string;
  emailAddress?: string;
}) => {
  const talentEmail = `${name} <${emailAddress}>`;
  const mailto = ccEmail
    ? `mailto:${talentEmail}?cc=${ccEmail}`
    : `mailto:${talentEmail}`;
  return (
    <>
      <Icon size={14} variant="envelope" css={{ mr: "$2" }} />
      <ExternalLink
        to={mailto}
        eventName={"agencyDashboard:jobs:emailLink"}
        css={{
          fontSize: "$14",
          lineHeight: "$22",
        }}
      >
        Send email
      </ExternalLink>
    </>
  );
};

const AvatarContainer = styled("div", {});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSingleAvatarSize = (sizes: any): sizes is AssetImageSize => {
  return (
    sizes.width !== undefined ||
    sizes.height !== undefined ||
    sizes.aspectRatio !== undefined ||
    sizes.fit !== undefined
  );
};

export const TalentListItem = ({
  talent,
  getSubHeading = () => null,
  isRemovable = () => false,
  onRemove = () => {
    throw new Error(
      "onRemove not implemented but isRemovable evaluates to true",
    );
  },
  isCheckable = () => false,
  isChecked = () => {
    throw new Error(
      "isChecked not implemented but isCheckable evaluates to true",
    );
  },
  onCheck = () => {
    throw new Error(
      "onCheck not implemented but isCheckable evaluates to true",
    );
  },
  getLink,
  showEmailLink,
  showEmailSubheader,
  showContactData,
  ccEmail,
  removeText,
  avatarSizes = {
    "@initial": {
      width: 50,
      aspectRatio: 1,
    },
    "@bp2": { width: 75, aspectRatio: 1 },
    "@bp4": { width: 100, aspectRatio: 1 },
  },
  avatarCss = undefined,
  onClick,
  url,
  alignItems = "center",
  showTalentDescriptorText,
  variant,
  openInNewWindow = false,
  disabled = false,
  checkboxSize = { width: 42, height: 42 },
  onReview,
  showVertical = false,
  isRemoveText,
}: TalentListItemProps) => {
  const { featureEnabled } = useFeatures();
  const profile = talent.profile as TalentProfile;

  const featuredImage = talent.featuredAsset;

  const primaryLocation = profile.primaryLocation?.name;

  const vertical = profile.vertical;

  const history = useHistory();

  const talentUrl = `/creatives/${talent.slug}`;
  const LinkWrapper =
    getLink && getLink(talent)
      ? ({ children }: { children: React.ReactNode }) => (
          <LinkWrapperContainer
            onClick={() => {
              if (onClick) {
                onClick(talent);
              } else {
                history.push(getLink(talent));
              }
            }}
          >
            {children}
          </LinkWrapperContainer>
        )
      : React.Fragment;

  const TalentLink = ({ children }: { children: React.ReactNode }) => (
    <a
      target={openInNewWindow ? "_blank" : "_self"}
      href={url ? url(talent.slug) : talentUrl}
      style={{ textDecoration: "none" }}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );

  const initialAvatarSize = isSingleAvatarSize(avatarSizes)
    ? avatarSizes
    : avatarSizes["@initial"] || {};
  let otherAvatarSizes: ResponsiveValue<AssetImageSize> = {};

  if (!isSingleAvatarSize(avatarSizes)) {
    // eslint-disable-next-line
    const { "@initial": initial, ...otherSizes } = avatarSizes;
    otherAvatarSizes = otherSizes;
  }

  const dietaryRequirements = (
    talent.profile as TalentProfile
  ).dietaryRequirements
    ?.join(", ")
    .toLowerCase();

  const displayDietaryRequirements = (
    dietaryRequirements: string | undefined,
  ) => {
    if (dietaryRequirements === "no requirements" || !dietaryRequirements) {
      return <Text variant="meta">No dietary or allergy requirements</Text>;
    }
    return (
      <Text variant="meta">
        Diet/allergies: {uppercaseFirstLetter(dietaryRequirements)}
      </Text>
    );
  };

  const pronouns = (talent.profile as TalentProfile).pronouns;

  return (
    <>
      <TalentListItemContainer
        key={talent.id}
        as={isCheckable(talent) ? "label" : "div"}
        variant={variant}
      >
        <LinkWrapper>
          <LinkWrapperInner alignItems={alignItems}>
            {featuredImage && (
              <TalentLink>
                <AvatarContainer
                  css={{
                    flexShrink: 0,
                    ...initialAvatarSize,
                    ...Object.fromEntries(
                      Object.entries(otherAvatarSizes).map(([key, value]) => [
                        key,
                        { minWidth: value.width },
                      ]),
                    ),
                  }}
                >
                  <AssetImage
                    alt={talent.name}
                    asset={featuredImage}
                    size={avatarSizes}
                    imgCss={avatarCss ? avatarCss : { display: "block" }}
                  />
                </AvatarContainer>
              </TalentLink>
            )}

            <TalentDetails>
              {disabled ? (
                <Text variant="body" css={{ color: "$grey6" }}>
                  {talent.name} {pronouns && `(${pronounsMap[pronouns]})`}
                </Text>
              ) : (
                <TalentLink>
                  <Text
                    variant="b2Bold"
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {showVertical && (
                      <Icon
                        variant={verticalIconMapAlt[vertical]}
                        size={30}
                        css={{
                          mr: "$3",
                        }}
                      />
                    )}
                    {talent.name} {pronouns && `(${pronounsMap[pronouns]})`}
                  </Text>
                </TalentLink>
              )}

              {showTalentDescriptorText && (
                <Text variant="meta" color="grey6">
                  {verticalMap[vertical]} - {primaryLocation}
                </Text>
              )}

              {getSubHeading(talent)}
              {showContactData && (
                <Grid
                  css={{
                    gridColumns: 1,
                    alignItems: "center",
                    "@bp2": {
                      gridColumns: 2,
                      gridGap: "$2",
                    },
                    "@bp5": {
                      gridTemplateColumns: "auto auto auto",
                      gridGap: "$9",
                    },
                  }}
                >
                  <Flex css={{ alignItems: "center" }}>
                    <EmailSubheader
                      name={talent.name}
                      emailAddress={talent.email}
                      ccEmail={ccEmail}
                    />
                  </Flex>
                  <Flex css={{ alignItems: "center" }}>
                    <Icon size={14} variant="phone" css={{ mr: "$2" }} />
                    <Text variant="meta">{talent.phoneNumber}</Text>
                  </Flex>
                  <Flex
                    css={{
                      alignItems: "center",
                      "@bp2": { gridColumn: "1 / 3" },
                      "@bp5": { gridColumn: "3" },
                    }}
                  >
                    <Icon size={14} variant="dietAndFood" css={{ mr: "$2" }} />
                    {displayDietaryRequirements(dietaryRequirements)}
                  </Flex>
                </Grid>
              )}
              {showEmailSubheader && (
                <Flex css={{ alignItems: "center", mt: "$1" }}>
                  <EmailSubheader
                    name={talent.name}
                    emailAddress={talent.email}
                    ccEmail={ccEmail}
                  />
                </Flex>
              )}
              {removeText && (
                <Text
                  variant={
                    featureEnabled("job_details_revamp") ? "meta" : undefined
                  }
                  color={
                    featureEnabled("job_details_revamp") ? "grey6" : undefined
                  }
                  css={{
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    onRemove(talent);
                  }}
                >
                  {removeText}
                </Text>
              )}
            </TalentDetails>

            {showEmailLink && (
              <EmailLinkContainer>
                <EmailLink
                  to={`mailto:${talent.name} <${talent.email}>`}
                  eventName={"agencyDashboard:jobs:emailLink"}
                >
                  Email
                </EmailLink>
              </EmailLinkContainer>
            )}
          </LinkWrapperInner>
        </LinkWrapper>
        {isCheckable(talent) && (
          <Checkbox
            data-test-id="TalentCheckbox"
            css={mergeCss(
              {
                backgroundColor: "$grey1",
                "&::before": { backgroundImage: "unset" },
              },
              checkboxSize,
            )}
            defaultChecked={isChecked(talent)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target;
              onCheck(talent, checked);
            }}
          />
        )}

        {onReview && <ReviewForm talent={talent} onReview={onReview} />}

        {isRemovable(talent) && !removeText && (
          <RemoveButton
            data-test-id="TalentListItem-Remove"
            variant="unstyled"
            onClick={(e) => {
              e.preventDefault();
              onRemove(talent);
            }}
            css={{
              p: "$3",
            }}
          >
            {isRemoveText ? (
              <Text
                variant="b3"
                css={{
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                color="grey4"
              >
                Remove
              </Text>
            ) : (
              <Icon variant="trash" size={14} color="grey6" />
            )}
          </RemoveButton>
        )}
      </TalentListItemContainer>
    </>
  );
};
