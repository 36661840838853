import { camelCase, startCase } from "lodash";
import { DropdownPill } from "src/ccl/blocks";
import { AvailableIcon } from "src/ccl/document";
import { FilterOption } from "src/ccl/filtering";
import { buildOptionProps } from "src/components/filtering";
import { Gender } from "src/graphql/types";
import { sentenceCase } from "src/utils/lang";

interface GenderFilterProps {
  variant?: "dropdown" | "pill";
  genders: Gender[];
  onChange: (states: Gender[]) => void;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}
const genderOptions = Object.entries(Gender);

export const GenderFilterOptions = ({
  variant,
  genders,
  onChange,
}: GenderFilterProps) => (
  <>
    <FilterOption
      variant={variant}
      label="All"
      icon="genders"
      {...buildOptionProps(genders, onChange)}
    />
    {genderOptions.map(([name, gender], i) => (
      <FilterOption
        variant={variant}
        key={i}
        label={sentenceCase(startCase(name))}
        icon={camelCase(name) as AvailableIcon}
        {...buildOptionProps(genders, onChange, gender)}
      />
    ))}
  </>
);

export const GenderFilter = ({
  genders,
  onChange,
  selectedFilter,
  setSelectedFilter,
}: GenderFilterProps) => {
  const active = genders.length > 0;
  return (
    <DropdownPill
      active={active}
      text="Gender"
      icon="userOutline"
      withHoverEffect
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    >
      <GenderFilterOptions genders={genders} onChange={onChange} />
    </DropdownPill>
  );
};
