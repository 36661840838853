import React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "src/ccl/stitches";
import { Flex, Box } from "src/ccl/layout";
import { BaseExternalLinkProps, ExternalLink, Link } from "src/ccl/navigation";
import { AvailableIcon, Icon, Text } from "src/ccl/document";

export interface TabbedMenuItemProps {
  url: string;
  match: RegExp;
  text: string;
  condensedText?: string;
  variant?: "tab" | "link";
  icon?: AvailableIcon;
}

const linkStyles = (active: boolean) => ({
  backgroundColor: active ? "$black" : "$grey6",
  color: "$white",
  textDecoration: "none",
  padding: "$4",
  py: active ? "$5" : "$0",
  "@bp4": {
    px: "$8",
  },
});

const LinkBody = ({
  text,
  condensedText = text,
  variant = "tab",
}: Pick<TabbedMenuItemProps, "text" | "condensedText" | "variant">) => (
  <>
    {variant === "link" ? (
      <Text variant="meta" color="white" css={{ textDecoration: "underline" }}>
        {text}
      </Text>
    ) : (
      <Text
        variant={{ "@initial": "small", "@bp2": "body" }}
        css={{
          color: "$white",
          fontWeight: "$medium",
          fontFamily: "$sans",
          "@bp5": {
            fontSize: "$20",
            lineHeight: "$30",
          },
        }}
      >
        <Box
          css={{
            display: "block",
            textTransform: "uppercase",
            "@bp2": { display: "none" },
          }}
        >
          {condensedText}
        </Box>
        <Box css={{ display: "none", "@bp2": { display: "block" } }}>
          {text}
        </Box>
      </Text>
    )}
  </>
);

export const TabbedMenuItem = ({
  url,
  text,
  match,
  condensedText,
  icon,
  variant,
}: TabbedMenuItemProps) => {
  const location = useLocation();
  const active = match.test(location.pathname + location.search);

  return (
    <Link to={url} css={linkStyles(active)}>
      {icon ? (
        <>
          <Icon
            variant={icon}
            size={16}
            color="white"
            css={{ display: "block", "@bp2": { display: "none" } }}
          />
          <Box css={{ display: "none", "@bp2": { display: "initial" } }}>
            <LinkBody
              text={text}
              condensedText={condensedText}
              variant={variant}
            />
          </Box>
        </>
      ) : (
        <LinkBody text={text} condensedText={condensedText} />
      )}
    </Link>
  );
};

export const TabbedMenuExternalItem = ({
  to,
  text,
  eventName,
  amplitudeEvent,
  eventValues,
  variant = "tab",
  icon,
}: Omit<TabbedMenuItemProps, "match" | "url"> & BaseExternalLinkProps) => (
  <ExternalLink
    to={to}
    amplitudeEvent={amplitudeEvent}
    eventName={eventName}
    eventValues={eventValues}
    css={linkStyles(false)}
  >
    {icon ? (
      <>
        <Icon
          variant={icon}
          size={16}
          color="white"
          css={{ display: "block", "@bp2": { display: "none" } }}
        />
        <Box css={{ display: "none", "@bp2": { display: "initial" } }}>
          <LinkBody text={text} />
        </Box>
      </>
    ) : (
      <LinkBody text={text} variant={variant} />
    )}
  </ExternalLink>
);

export const TabGroup = styled(Flex, {
  alignItems: "center",
});

export const TabbedMenu = ({ children }: { children: React.ReactNode }) => (
  <Box
    css={{
      backgroundColor: "$grey6",
      "@bp2": {
        px: "$6",
      },
      "@bp4": {
        px: "$17",
      },
    }}
  >
    <Flex
      css={{
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {children}
    </Flex>
  </Box>
);
