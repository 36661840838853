import React, { useState, useEffect } from "react";
import { Box, ContentContainer, Flex } from "src/ccl/layout";
import {
  ProfileAttribute,
  TalentProfile,
  TalentVertical,
  User,
  VerticalConfiguration,
} from "src/graphql/types";
import { useHandleBookmark, useStoreModel } from "src/hooks";
import { SignInModal } from "src/ccl/document";
import { VerticalTitle } from "src/components/talent/talentProfile/VerticalTitle";
import { verticalMap } from "src/utils/user";
import { LinksSection } from "src/components/talent/talentProfile/LinksSection";
import { Biography } from "src/components/talent/talentProfile/Biography";
import { MainDetails } from "src/components";
import { Button } from "src/ccl/navigation";

export interface VerticalTalentProfileHeaderProps {
  vertical: TalentVertical;
  talent: User;
  profileAttributes: ProfileAttribute[];
  isRemovable: boolean;
  isShortlisted: boolean;
  showShortlistButton: boolean;
  shortlistButtonClick: () => void;
  handleBookmark?: (isChecked: boolean, talentId: string) => void;
  isBookmarkDisabled?: boolean;
}

interface TalentProfileHeaderProps {
  verticalConfig: VerticalConfiguration;
  talent: User;
  isRemovable: boolean;
  isShortlisted: boolean;
  shortlistButtonClick: (shortlisted: boolean) => void;
  showShortlistButton: boolean;
  onAddToCollection?: (talent: User) => void;
}

export const TalentProfileHeader = ({
  verticalConfig,
  talent,
  isRemovable,
  isShortlisted,
  shortlistButtonClick,
  showShortlistButton,
  onAddToCollection,
}: TalentProfileHeaderProps) => {
  const { isBooker, loggedIn } = useStoreModel("currentUser");
  const { handleBookmark: toggleBookmark, loading: bookmarkLoading } =
    useHandleBookmark();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const toggleSignInModal = () => {
    setSignInModalOpen(!signInModalOpen);
  };
  const handleBookmark = isBooker
    ? toggleBookmark
    : !loggedIn
    ? toggleSignInModal
    : undefined;

  useEffect(() => {
    if (loggedIn && signInModalOpen) {
      toggleSignInModal();
    }
  }, [loggedIn]);

  const vertical = verticalConfig.id;
  const profile = talent.profile as TalentProfile;
  const creativeType: string[] =
    JSON.parse(
      profile.verticalData?.find((d) => d.key === "creative_type")?.value ||
        "null",
    ) || [];
  const prettyCreativeType: string =
    creativeType.length === 1
      ? creativeType[0].replace(/_/g, " ")
      : verticalMap[vertical];
  const props = {
    vertical: vertical,
    talent: talent,
    profileAttributes: verticalConfig.profileAttributes,
    isRemovable: isRemovable,
    isShortlisted: isShortlisted,
    shortlistButtonClick: () => shortlistButtonClick(isShortlisted),
    showShortlistButton: showShortlistButton,
    handleBookmark: handleBookmark,
    isBookmarkDisabled: !isBooker || bookmarkLoading,
  };

  return (
    <ContentContainer variant="noGutter" css={{ py: "$5", height: "100%" }}>
      <SignInModal isOpen={signInModalOpen} onClose={toggleSignInModal} />

      <Box
        data-test-id="TalentProfileHeader"
        css={{
          px: "$8",
          height: "100%",
          "@bp1": {
            position: "relative",
          },
        }}
      >
        <VerticalTitle
          vertical={vertical}
          agency={profile.agency}
          category={talent.groups?.[0]?.name}
          creativeType={prettyCreativeType}
        />

        <Box
          css={{
            order: 1,
            "@bp2": {
              order: "inherit",
            },
          }}
        >
          <Flex css={{ gap: "$5", flexDirection: "column" }}>
            <MainDetails
              talent={talent}
              isBookmarkDisabled={!isBooker || bookmarkLoading}
              handleBookmark={handleBookmark}
              onAddToCollection={
                onAddToCollection ? () => onAddToCollection(talent) : undefined
              }
            />

            <LinksSection talent={talent} />
          </Flex>
          {profile.scrubbedBiography &&
            profile.scrubbedBiography.trim() !== "" && (
              <Biography body={profile.scrubbedBiography} />
            )}

          <Box
            css={{
              position: "fixed",
              bottom: "$14",
              right: "$8",
              left: "$8",
              "@bp1": {
                position: "absolute",
                bottom: "$0",
              },
            }}
          >
            {showShortlistButton && (
              <Button
                variant={isShortlisted ? "green" : "primaryCta"}
                css={{ width: "100%", borderRadius: "$md" }}
                onClick={props.shortlistButtonClick}
                disabled={!isRemovable}
              >
                {isShortlisted ? "Shortlisted" : "Shortlist"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ContentContainer>
  );
};
