import { actionOn, ActionOn } from "easy-peasy";
import { queryCache, QueryCacheModel } from "./queryCache";
import { draftJobModelV2, DraftJobModelV2 } from "./jobsDraftV2";
import {
  JobResubmissionDraft,
  jobResubmissionDraft,
} from "./jobResubmissionDraft";
import { graphQLClient } from "src/graphql/client";

export interface JobsModel {
  draftV2: DraftJobModelV2;
  resubmissionDraft: JobResubmissionDraft;
  queryCache: QueryCacheModel;
  onDraftReset: ActionOn<JobsModel>;
}
export const jobsModel: JobsModel = {
  draftV2: draftJobModelV2,
  resubmissionDraft: jobResubmissionDraft,
  queryCache: queryCache,
  onDraftReset: actionOn(
    (actions) => actions.draftV2.reset,
    (state) => {
      graphQLClient.cache.evict({
        id: "ROOT_QUERY",
        fieldName: "verticalTalentSearch",
      });
      state.queryCache.createJob = null;
    },
  ),
};
