import { gql } from "@apollo/client";

export const JOB_DETAILS_FRAGMENT = gql`
  fragment JobDetails on Job {
    id
    slug
    virtual
    kind
    vertical
    name
    brand
    location
    startDate
    endDate
    talentRequired
    budget
    description
    usage
    deliverables
    jobLength
    booker {
      name
    }
    deckFiles {
      id
      url
      filename
    }
    headcountEstimate
    travelExpenseCover
    nudityType
    additionalNotes
    usages {
      mediaType
      form
      location
      length
    }
  }
`;

export const JOB_DETAILS_SPLIT_ADDRESS_FRAGMENT = gql`
  fragment JobDetailsSplitAddress on Job {
    addressLine1
    addressLine2
    city
    postcode
    country
  }
`;

export const JOB_VERTICAL_DETAILS_FRAGMENT = gql`
  fragment JobVerticalDetails on Job {
    jobVerticals {
      budget
      vertical
      deliverables
      jobLength
      talentRequired
      timeslots {
        from
        to
      }
    }
  }
`;
