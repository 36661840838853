import { flatten } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PortfolioImage } from "src/ccl/document";
import { PortfolioImageModal } from "src/ccl/document/modal/PortfolioImageModal";
import { Box } from "src/ccl/layout";
import {
  PortfolioImage as PortfolioImageType,
  TalentProfile,
  User,
} from "src/graphql/types";
export const PortfolioColumnCount = 3;

const buildColumns = (items: PortfolioImageType[]) => {
  const columns = new Array(PortfolioColumnCount)
    .fill([])
    .map(() => new Array(0));

  items.forEach((item, i) => {
    columns[i % PortfolioColumnCount].push(item);
  });

  return columns;
};

export const PortfolioImageGallery = ({
  talent,
  onItemClick,
}: {
  talent: User;
  onItemClick?: (portfolioImage: PortfolioImageType) => void;
}) => {
  const params = new URLSearchParams(window?.location.search);
  const selectedCategory = params.get("t");
  const history = useHistory();

  const [selectedPortfolioImage, setSelectedPortfolioImage] =
    useState<PortfolioImageType | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (!selectedCategory) {
    params.append("t", "All");
    history.replace(`?${params.toString()}`);
  }

  const profile = talent.profile as TalentProfile;
  const categories = profile.portfolioImageCategories;
  const portfolioImages = profile.portfolioImages || [];

  const visibleItems =
    selectedCategory === "All"
      ? portfolioImages
      : categories.find(({ name }) => name === selectedCategory)
          ?.portfolioImages || [];

  const columns = flatten(buildColumns(visibleItems));

  return (
    <Box data-test-id="PortfolioImageGallery" css={{ height: "100%" }}>
      <Box
        css={{
          columnCount: "1",
          columnGap: "1em",
          margin: "1.5em",
          padding: "0",
          fontSize: ".85em",
          "@bp1": {
            columnCount: "2",
          },
        }}
      >
        {columns.map((portfolioImage) => (
          <Box
            key={`portfolio-item-${portfolioImage.id}`}
            css={{ cursor: "pointer" }}
            onClick={() => {
              if (onItemClick) {
                onItemClick(portfolioImage);
              } else {
                setSelectedPortfolioImage(portfolioImage);
                setModalOpen(true);
              }
            }}
          >
            <PortfolioImage
              asset={portfolioImage}
              alt={`An image of ${talent.name}`}
            />
          </Box>
        ))}
      </Box>

      {selectedPortfolioImage !== null && (
        <PortfolioImageModal
          talent={talent}
          portfolioImage={selectedPortfolioImage}
          isOpen={modalOpen}
          onClose={toggleModal}
        />
      )}
    </Box>
  );
};
