import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, FormikErrors } from "formik";
import { useMutation } from "@apollo/client";
import { Box, Container, Flex } from "src/ccl/layout";
import { useCTSteps } from "src/components/dashboards/agents/talentManagement/add/useCTSteps";
import { useScrollToTop, useStoreModel, useUserKindContext } from "src/hooks";
import { CREATE_AGENCY_TALENT_MUTATION } from "src/graphql/mutations";
import {
  Mutation,
  TalentVertical,
  PortfolioImage,
  PortfolioImageCategory,
} from "src/graphql/types";
import { PortfolioForm, removeImages } from "src/components/dashboards/agents";
import { verticalMap } from "src/utils/user";
import { sendToAmplitude } from "src/utils/analytics";
import { ValidationBlock } from "src/ccl/feedback";
import { Icon, Text } from "src/ccl/document";
import {
  buildPortfolioImages,
  buildVerticalData,
  parseDateOfBirth,
} from "src/utils/createTalent";
import { Button, Link } from "src/ccl/navigation";
import { FullHeaderPage } from "src/ccl/templates";

interface PortfolioProps {
  vertical: TalentVertical;
}

const Step = ({
  number,
  label,
  active,
}: {
  number: number;
  label: string;
  active: boolean;
}) => (
  <Flex
    css={{
      gap: "16px",
      alignItems: "center",
    }}
  >
    <Flex
      css={{
        backgroundColor: active ? "$black" : "$grey5",
        width: "30px",
        height: "30px",
        borderRadius: "$round",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text color="white" weight="bold">
        {number}
      </Text>
    </Flex>
    {active && <Text variant="nh4">{label}</Text>}
  </Flex>
);

export const Portfolio = ({ vertical }: PortfolioProps) => {
  const currentUser = useStoreModel("currentUser");
  const draftTalent = useStoreModel("draftTalent");
  const {
    portfolioCategories,
    addPortfolioCategory,
    deletePortfolioCategory,
    setPortfolioCategories,
    updatePortfolioCategory,
    addPortfolioImages,
  } = draftTalent;

  const [activeCategoryName, setActiveCategoryName] = useState<string>();
  const activeCategory = portfolioCategories?.find(
    ({ name }) => name === activeCategoryName,
  );

  const setPortfolioImages = activeCategory
    ? (images: PortfolioImage[]) => {
        updatePortfolioCategory({ ...activeCategory, portfolioImages: images });
      }
    : draftTalent.setPortfolioImages;

  const portfolioImages = activeCategory
    ? activeCategory.portfolioImages
    : draftTalent.portfolioImages || [];

  const steps = useCTSteps();
  const history = useHistory();
  const { pathname } = useLocation();
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;

  useEffect(() => {
    sendToAmplitude("adds creative portfolio - lands on page", {
      url: pathname,
      userType: userKindContext,
      agency: agencyName,
    });
  }, []);

  const details = draftTalent.details;
  const talentProfile = details?.talentProfile;
  const agency = currentUser?.me?.agency;
  const parsedVerticalData = buildVerticalData(
    talentProfile?.verticalData || {},
  );
  const parsedPortfolioImages = buildPortfolioImages(
    draftTalent.portfolioImages || [],
    draftTalent.portfolioCategories || [],
  );

  const [createAgencyTalent, { loading, error }] = useMutation<Mutation>(
    CREATE_AGENCY_TALENT_MUTATION,
    {
      variables: {
        ...details,
        talentProfile: {
          ...talentProfile,
          vertical,
          portfolioImages: parsedPortfolioImages,
          dateOfBirth: parseDateOfBirth(talentProfile?.dateOfBirth),
          agencyId: agency?.id,
          verticalData: parsedVerticalData,
        },
      },
    },
  );

  useScrollToTop([error]);

  if (
    draftTalent?.details &&
    draftTalent.details?.email === undefined &&
    history
  ) {
    history.replace("/dashboard/agent/talent/new");
  }

  return (
    <FullHeaderPage>
      <Formik
        initialValues={{ portfolioImages: [], portfolioCategories: [] }}
        validate={() => {
          const errors: FormikErrors<{
            portfolioImages: PortfolioImage[];
            portfolioCategories: PortfolioImageCategory[];
          }> = {};

          if (
            !draftTalent.portfolioImages ||
            draftTalent.portfolioImages.length === 0
          ) {
            errors.portfolioImages = "Must add at least one image";
          }

          const emptyCategory = draftTalent.portfolioCategories?.find(
            ({ portfolioImages }) => portfolioImages.length === 0,
          );
          if (emptyCategory) {
            errors.portfolioCategories = `Cannot post empty categories, please add images to ${emptyCategory.name}`;
          }

          return Object.keys(errors).length ? errors : undefined;
        }}
        onSubmit={async () => {
          const talent = await createAgencyTalent();

          if (!talent?.errors && vertical) {
            draftTalent.reset();

            history.push(
              `/dashboard/Agent/Talent?v=${verticalMap[vertical]}s`,
              {
                justCreatedTalent: {
                  name: details?.name,
                  slug: talent.data?.createAgencyTalent?.slug || "",
                  agencyName: agency?.name,
                  verticalName: verticalMap[vertical],
                },
              },
            );
          }
        }}
      >
        {({ submitForm, errors, setErrors }) => (
          <Flex css={{ flexDirection: "column", gap: "47px" }}>
            <Flex css={{ flexDirection: "column", gap: "30px" }}>
              <Container css={{ pt: "14px" }}>
                <Flex
                  css={{
                    gap: "$4",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to={`/dashboard/agent/talent/new/profile?v=${verticalMap[vertical]}s`}
                    css={{ textDecoration: "none" }}
                  >
                    <Icon variant="chevronLeft" size={28} />
                  </Link>
                  <Text variant="nh3">Add a new talent</Text>
                </Flex>
              </Container>
              <Flex
                css={{
                  flexDirection: "column",
                  gap: "27px",
                  "@md": {
                    gap: "33px",
                  },
                }}
              >
                <Container>
                  <Flex
                    css={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Flex css={{ gap: "26px" }}>
                      {steps.map((label, i) => (
                        <Step
                          key={`step-${i}`}
                          active={1 === i}
                          number={i + 1}
                          label={label}
                        />
                      ))}
                    </Flex>
                    <Flex
                      css={{
                        display: "none",
                        gap: "$8",
                        "@md": {
                          display: "flex",
                        },
                      }}
                    >
                      <Button
                        data-test-id="FormHeaderCTA"
                        variant="primaryCta"
                        onClick={() => {
                          sendToAmplitude(
                            "adds creative portfolio - clicks continue",
                            {
                              url: pathname,
                              userType: userKindContext,
                              agency: agencyName,
                            },
                          );
                          submitForm();
                        }}
                        disabled={loading}
                      >
                        {loading ? "Posting..." : "Post profile"}
                      </Button>
                    </Flex>
                  </Flex>
                </Container>
                <Box
                  css={{
                    height: "$2",
                    backgroundColor: "$grey1",
                  }}
                >
                  <Box
                    css={{
                      height: "100%",
                      backgroundColor: "$black",
                      width: `${(2 / 2) * 100}%`,
                      transition: "0.5s ease width",
                    }}
                  ></Box>
                </Box>
              </Flex>
            </Flex>
            <Container>
              <Flex css={{ flexDirection: "column", width: "100%" }}>
                {Object.keys(errors).length > 0 && (
                  <ValidationBlock
                    variant="error"
                    title="Portfolio is invalid"
                    body={errors.portfolioImages || errors.portfolioCategories}
                    css={{ mt: "$8" }}
                  />
                )}
                {error && (
                  <ValidationBlock
                    variant="error"
                    title="Something went wrong"
                    body={error.message}
                    css={{ mt: "$8" }}
                  />
                )}

                <Box
                  css={{
                    mt: "$5",
                  }}
                >
                  <PortfolioForm
                    context="addTalent"
                    vertical={vertical}
                    onDeleteActiveCategory={() => {
                      if (
                        activeCategoryName === "Polaroids" &&
                        vertical === TalentVertical.FashionModel
                      ) {
                        setErrors({
                          portfolioCategories:
                            "Cannot remove Polariods category",
                        });
                        return;
                      }

                      activeCategory && deletePortfolioCategory(activeCategory);
                      setActiveCategoryName("");
                      setErrors({});
                    }}
                    onSelectCategory={(category) => {
                      setActiveCategoryName(category?.name);
                      setErrors({});
                    }}
                    onCreateCategory={(name) => {
                      addPortfolioCategory({
                        id: "",
                        name,
                        portfolioImages: [],
                      });
                      setErrors({});
                    }}
                    onEditActiveCategory={(name) => {
                      if (!activeCategory) {
                        return;
                      }
                      setPortfolioCategories(
                        portfolioCategories?.map((category) => {
                          if (category.name !== activeCategory.name) {
                            return category;
                          }
                          return {
                            ...activeCategory,
                            name,
                          };
                        }) || [],
                      );
                      setActiveCategoryName(name);
                      setErrors({});
                    }}
                    portfolioImages={draftTalent.portfolioImages || []}
                    portfolioCategories={portfolioCategories || []}
                    activeCategory={activeCategory}
                    sortPortfolioImages={setPortfolioImages}
                    onCategoriseImages={(images, categories) => {
                      const updatedCategories =
                        portfolioCategories?.map((category) => {
                          if (
                            !categories.find(
                              ({ name }) => name === category.name,
                            )
                          ) {
                            return category;
                          }
                          const updatedImages = category.portfolioImages.concat(
                            removeImages({
                              toRemove: category.portfolioImages,
                              removeFrom: images,
                            }),
                          );
                          return {
                            ...category,
                            portfolioImages: updatedImages,
                          };
                        }) || [];
                      setPortfolioCategories(updatedCategories);
                      setErrors({});
                    }}
                    onDeleteImages={(images) => {
                      setPortfolioImages(
                        removeImages({
                          toRemove: images,
                          removeFrom: portfolioImages,
                        }),
                      );
                      if (!activeCategory) {
                        setPortfolioCategories(
                          (portfolioCategories || []).map((category) => ({
                            ...category,
                            portfolioImages: removeImages({
                              toRemove: images,
                              removeFrom: category.portfolioImages,
                            }),
                          })),
                        );
                      }
                      setErrors({});
                    }}
                    onUpload={(newImages) => {
                      if (activeCategory) {
                        updatePortfolioCategory({
                          ...activeCategory,
                          portfolioImages:
                            activeCategory.portfolioImages.concat(newImages),
                        });
                      }

                      addPortfolioImages(newImages);
                      setErrors({});
                    }}
                  />
                </Box>

                <Flex
                  css={{
                    pb: "$6",
                    gap: "$8",
                    flexDirection: "column",
                    "@md": {
                      display: "none",
                    },
                  }}
                >
                  <Button
                    data-test-id="CAJContinueButton"
                    variant="primaryCta"
                    onClick={() => {
                      sendToAmplitude(
                        "adds creative portfolio - clicks continue",
                        {
                          url: pathname,
                          userType: userKindContext,
                          agency: agencyName,
                        },
                      );
                      submitForm();
                    }}
                    disabled={loading}
                  >
                    {loading ? "Posting..." : "Post profile"}
                  </Button>
                </Flex>
              </Flex>
            </Container>
          </Flex>
        )}
      </Formik>
    </FullHeaderPage>
  );
};
