import React from "react";
import Select, {
  components,
  OnChangeValue,
  ValueContainerProps,
  DropdownIndicatorProps,
} from "react-select";
import { camelCase } from "lodash";
import type * as Stitches from "@stitches/react";
import CreatableSelect from "react-select/creatable";
import { FormikHandlers } from "formik";
import { ClearIndicator } from "./SelectIcons";
import { controlStyles, selectStyles } from "./SelectStyles";
import { mergeCss, styled } from "src/ccl";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";

import { tokens } from "src/ccl/stitches/theme";

export interface Option {
  value: string;
  label: string;
}

export interface SelectReactProps {
  isClearable?: boolean;
  showSeparator?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  backgroundColor?: string;
  color?: keyof typeof tokens.colors;
  options?: Option[];
  onChange: (value: Option | undefined) => void;
  onInputChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  initialValues?: Option | [];
  value?: Option;
  label?: string;
  additionalContent?: React.ReactNode;
  valueContent?: React.ReactNode;
  optional?: boolean;
  placeholder?: string;
  id?: string;
  variant?: "rebrand";
  labelCss?: Stitches.CSS;
  boxCss?: Stitches.CSS;
  dropdownCss?: Stitches.CSS;
  fontFamily?: keyof typeof tokens.fonts;
  fontSize?: keyof typeof tokens.fontSizes;
  fontWeight?: keyof typeof tokens.fontWeights;
  creatable?: boolean;
  maxMenuHeight?: number;
  withInput?: boolean;
  showBorder?: boolean;
  hasMargin?: boolean;
  name?: string;
  onBlurFormik?: FormikHandlers["handleBlur"];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Style = Record<string, any>;

const base = (
  variant?: "rebrand",
  color?: keyof typeof tokens.colors,
  fontFamily?: keyof typeof tokens.fonts,
  fontSize?: keyof typeof tokens.fontSizes,
  fontWeight?: keyof typeof tokens.fontWeights,
  withInput?: boolean,
  hasMargin?: boolean,
) => {
  return {
    option: (
      provided: {},
      state: { isFocused: boolean; isSelected: boolean },
    ) => ({
      ...provided,
      color: state.isSelected ? tokens.colors.white : tokens.colors.black,
      backgroundColor: state.isSelected
        ? tokens.colors.black
        : state.isFocused
        ? tokens.colors.grey2
        : tokens.colors.white,
      ":active": {
        backgroundColor: tokens.colors.white,
      },
      fontFamily: fontFamily ? tokens.fonts[fontFamily] : undefined,
      fontSize: fontSize ? tokens.fontSizes[fontSize] : undefined,
      padding: variant === "rebrand" ? "10px" : undefined,
      borderRadius: variant === "rebrand" ? "8px" : undefined,
      width: variant === "rebrand" ? "100%" : undefined,
    }),
    ...selectStyles,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (base: any, state: any) => ({
      ...base,
      paddingTop: variant === "rebrand" ? "6px" : "4px",
      paddingBottom: variant === "rebrand" ? "6px" : undefined,
      paddingLeft: variant === "rebrand" ? "5px" : undefined,
      paddingRight: variant === "rebrand" ? "5px" : undefined,
      marginTop: variant === "rebrand" ? "8px" : "8px",
      filter:
        variant === "rebrand"
          ? "drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.2))"
          : undefined,
      position: state.selectProps.isExpandable ? "relative" : "absolute",
      border:
        variant === "rebrand" ? undefined : `2px solid ${tokens.colors.black}`,
      borderRadius: variant === "rebrand" ? "8px" : 0,
      zIndex: 1000,
    }),
    singleValue: (base: {}) => ({
      ...base,
      fontSize: fontSize ? tokens.fontSizes[fontSize] : tokens.fontSizes[16],
      fontFace: tokens.fonts.sansWide,
      padding: "0 8px",
      textDecoration: undefined,
      height: 22,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      color,
      fontWeight: fontWeight
        ? tokens.fontWeights[fontWeight]
        : tokens.fontWeights.regular,
      ...(withInput && { padding: "$0" }),
    }),
    valueContainer: (base: {}) => ({
      ...base,
      height: 22,
      alignContent: "center",
      marginBottom: hasMargin ? 18 : 0,
      marginTop: hasMargin ? 18 : 0,
      paddingLeft: variant === "rebrand" ? 18 : 8,
      fontFamily: fontFamily ? tokens.fonts[fontFamily] : undefined,
      fontSize: fontFamily ? tokens.fonts[fontFamily] : undefined,
      ...(withInput && { justifyContent: "flex-end", paddingLeft: "0px" }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: (base: {}, state: any) => ({
      ...base,
      height: 22,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      alignContent: "center",
      paddingLeft: !state.value ? 0 : 8,
    }),
  };
};
export const SelectReact = ({
  initialValues = [],
  value,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isSearchable = false,
  showSeparator = false,
  backgroundColor = "white",
  color = "black",
  onChange,
  onInputChange,
  options,
  label,
  additionalContent,
  optional,
  onFocus,
  onBlur,
  placeholder,
  id = camelCase(label),
  valueContent,
  variant,
  labelCss = {},
  boxCss = {},
  dropdownCss = {},
  fontFamily,
  fontSize,
  fontWeight,
  creatable = false,
  maxMenuHeight,
  withInput = false,
  showBorder = true,
  hasMargin = true,
  name = "",
  onBlurFormik,
}: SelectReactProps) => {
  const handleOnChange = (value: OnChangeValue<Option, false>) => {
    if (!value) {
      onChange(undefined);
    } else {
      onChange({
        value: (value as Option).value,
        label: (value as Option).label,
      });
    }
  };

  const StyledLabel = styled("label", {
    fontWeight: "$bold",
    mb: "$2",
    variants: {
      variant: {
        rebrand: {
          fontFamily: "$sansNew",
          fontSize: "$16",
          letterSpacing: "$wide",
          fontWeight: "$semiBold !important",
          lineHeight: "$loose",
          mb: "$6",
        },
      },
    },
  });

  const ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<Option, false>) => (
    <components.ValueContainer {...props}>
      <Flex
        css={{
          alignItems: "center",
        }}
      >
        {valueContent}
        {children}
      </Flex>
    </components.ValueContainer>
  );

  const DropdownIndicator = ({
    ...props
  }: DropdownIndicatorProps<Option, false>) => (
    <components.DropdownIndicator {...props}>
      <Flex>
        <Icon
          variant={variant === "rebrand" ? "selectArrowOutline" : "selectArrow"}
          size={variant === "rebrand" ? 10 : 14}
          color={color as keyof typeof tokens.colors}
          css={mergeCss(
            { px: "$4", cursor: "pointer", width: "auto" },
            dropdownCss,
          )}
        />
      </Flex>
    </components.DropdownIndicator>
  );

  const styles = {
    ...base(
      variant,
      color as keyof typeof tokens.colors,
      fontFamily,
      fontSize,
      fontWeight,
      withInput,
      hasMargin,
    ),
    control: (
      styles: Style,
      state: { isFocused: boolean; hasValue: boolean },
    ) => ({
      ...styles,
      ...controlStyles,
      border: showBorder
        ? variant === "rebrand" && !state.hasValue
          ? `2px solid ${tokens.colors.grey3}`
          : `2px solid ${tokens.colors.black}`
        : "none",
      ":hover": {
        border: showBorder
          ? variant === "rebrand" && !state.hasValue
            ? `2px solid ${tokens.colors.grey3}`
            : `2px solid ${tokens.colors.black}`
          : "none",
      },
      borderRadius: variant === "rebrand" ? "8px" : undefined,
      outline:
        state.isFocused && variant !== "rebrand"
          ? `2px solid ${tokens.colors.black}`
          : "none",
      backgroundColor: backgroundColor,
    }),
    placeholder: (base: {}) => ({
      ...base,
      paddingLeft: creatable ? 8 : 0,
    }),
  };

  return (
    <Box
      css={mergeCss({ width: "100%" }, boxCss)}
      data-test-id={`select-input-${id}`}
    >
      <Flex css={{ justifyContent: "space-between" }}>
        {label && (
          <StyledLabel
            htmlFor={label}
            css={mergeCss({}, labelCss)}
            variant={variant}
          >
            {label}
          </StyledLabel>
        )}
        {optional && (
          <Text variant="meta" color="grey6" css={{ mb: "$3" }}>
            (optional)
          </Text>
        )}
        {additionalContent}
      </Flex>
      {creatable ? (
        <CreatableSelect
          inputId={`select-${id}-input`}
          onInputChange={onInputChange}
          options={options}
          styles={styles}
          onChange={handleOnChange}
          isClearable
          value={value}
          components={{
            DropdownIndicator,
            ClearIndicator,
            ...(showSeparator ? {} : { IndicatorSeparator: null }),
            ValueContainer,
          }}
          placeholder={placeholder}
          onFocus={onFocus}
        />
      ) : (
        <Select
          placeholder={placeholder}
          styles={styles}
          components={{
            DropdownIndicator,
            ClearIndicator,
            ...(showSeparator ? {} : { IndicatorSeparator: null }),
            ValueContainer,
          }}
          onChange={handleOnChange}
          defaultValue={initialValues as Option}
          value={value}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isSearchable={isSearchable}
          id={id}
          name={name}
          options={options}
          onFocus={onFocus}
          onBlur={onBlur ?? onBlurFormik}
          maxMenuHeight={maxMenuHeight}
        />
      )}
    </Box>
  );
};
