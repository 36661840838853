import React, { useEffect, useState } from "react";
import { CSS, VariantProps } from "@stitches/react";
import { styled } from "src/ccl/stitches/config";
import { Text } from "src/ccl/document/text";

const slideTransitionSeconds = 0.2;

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
});

const InnerContainer = styled("div", {
  display: "inline-block",
  position: "relative",
  variants: {
    variant: {
      withIcons: {
        height: "28px",
        width: "56px",
      },
    },
  },
});

const Label = styled(Text, {
  transition: `${slideTransitionSeconds}s ease opacity`,
});

const LabelLeft = styled(Label, {
  paddingRight: "$3",
});

const LabelRight = styled(Label, {
  paddingLeft: "$3",
});

const Slider = styled("div", {
  backgroundColor: "$black",
  cursor: "pointer",
  width: "100%",
  height: "100%",
  padding: "5%",
});

const Handle = styled("div", {
  backgroundColor: "$white",
  height: "100%",
  width: "50%",
  transition: `${slideTransitionSeconds}s ease transform`,
  position: "relative",
  borderRadius: "4px",
});

const iconStyles: CSS = {
  opacity: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: `${slideTransitionSeconds}s ease opacity`,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const CheckedIcon = styled("div", iconStyles);
const UncheckedIcon = styled("div", iconStyles);

const Input = styled("input", {
  display: "none",

  [`&:checked + ${Slider} ${Handle}`]: {
    transform: "translateX(100%)",
  },
});

export interface ToggleProps extends VariantProps<typeof Input> {
  id: string;
  name?: string;
  checked: boolean;
  textChecked?: string | React.ReactNode;
  textUnchecked?: string;
  iconChecked?: React.ReactNode;
  iconUnchecked?: React.ReactNode;
  onCheck?: () => void;
  onUncheck?: () => void;
  uncheckedGreyBackground?: boolean;
  height?: number;
  width?: number;
}

export const Toggle = ({
  id,
  textChecked,
  textUnchecked,
  iconChecked,
  iconUnchecked,
  onCheck,
  onUncheck,
  checked: checkedProp,
  uncheckedGreyBackground = false,
  height = 18,
  width = 50,
  ...inputProps
}: ToggleProps) => {
  const [checked, setChecked] = useState(checkedProp);

  useEffect(() => {
    setChecked(checkedProp);
  }, [checkedProp, setChecked]);

  useEffect(() => {
    const callback = checked ? onCheck : onUncheck;

    if (!callback) {
      return;
    }

    setTimeout(callback, slideTransitionSeconds * 500);
  }, [checked]);

  return (
    <Container
      as="label"
      htmlFor={id}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {textUnchecked && (
        <LabelLeft css={{ opacity: checked ? 0.5 : 1 }} variant="meta">
          {textUnchecked}
        </LabelLeft>
      )}

      <InnerContainer
        variant={iconChecked || iconUnchecked ? "withIcons" : undefined}
        css={{ height, width }}
      >
        <Input
          type="checkbox"
          checked={checked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setChecked(e.target.checked)
          }
          id={id}
          {...inputProps}
        />
        <Slider
          data-test-id={`${id}-ToggleSlider`}
          css={{
            backgroundColor:
              uncheckedGreyBackground && !checked ? "$grey3" : "$black",
            borderRadius: "7px",
          }}
        >
          <Handle css={{ background: checked ? "$white" : "$black" }}>
            <UncheckedIcon css={{ opacity: checked ? 0 : 1 }}>
              {iconUnchecked}
            </UncheckedIcon>
            <CheckedIcon css={{ opacity: checked ? 1 : 0 }}>
              {iconChecked}
            </CheckedIcon>
          </Handle>
        </Slider>
      </InnerContainer>

      {textChecked && (
        <LabelRight css={{ opacity: checked ? 1 : 0.5 }} variant="button">
          {textChecked}
        </LabelRight>
      )}
    </Container>
  );
};
