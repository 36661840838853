import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { LinkButton, Button } from "src/ccl/navigation";
import {
  ShortlistMobileStack,
  ShortlistTray,
  ShortlistVerticals,
} from "src/components";
import { useFeatures, usePrevious, useStoreModel, useToggle } from "src/hooks";
import { pluralize } from "src/utils/lang";
import { getTalentVerticals } from "src/utils/user";
import { sendToAmplitude } from "src/utils/analytics";
import { DropdownPill, Pill } from "src/ccl/blocks";
import { useIsMobile } from "src/hooks/useIsMobile";
import { breakpoints } from "src/ccl";

export const ShortlistHeaderButton = forwardRef<HTMLDivElement>((_, ref) => {
  const { featureEnabled } = useFeatures();
  const storeModel = useStoreModel("jobs");
  const { pathname } = useLocation();
  const isMobile = useIsMobile(breakpoints.bp4);

  const {
    draftV2: { talentUsers, removeTalentUser, setTalentUsers },
  } = storeModel;

  const numberOfTalent = talentUsers?.length;
  const prevNumberOfTalent = usePrevious(numberOfTalent);
  const activeState = numberOfTalent > 0;

  const showBookCreatives =
    !pathname.includes("/jobs/new/shortlist") &&
    !pathname.includes("/dashboard/client/jobs") &&
    !pathname.includes("/dashboard/client/talent/marketplace") &&
    !pathname.includes("/dashboard/client/collections") &&
    !pathname.includes("/account") &&
    !numberOfTalent &&
    !isMobile;

  const history = useHistory();

  const [shortlistOpen, toggleShortlistOpen] = useToggle();
  const [showShortlistingReminder, setShowShortlistingReminder] =
    useState(true);

  const handleButtonClick = () => {
    !pathname.includes("/jobs/new/")
      ? history.push("/jobs/new/shortlist")
      : toggleShortlistOpen();
  };

  const talentVerticals = getTalentVerticals(talentUsers);
  const onContinue = () => {
    const multiVerticalBooking = talentVerticals.length > 1;
    const jobVertical = talentVerticals;
    sendToAmplitude("creative select - clicks continue cta", {
      multiVerticalBooking,
      jobVertical,
    });

    history.push("/jobs/new/details");
  };

  useEffect(() => {
    if (
      isMobile &&
      numberOfTalent === 1 &&
      pathname.includes("/jobs/new/shortlist")
    ) {
      toggleShortlistOpen();
    }

    if (
      !isMobile &&
      numberOfTalent >
        ((prevNumberOfTalent as number) &&
          (pathname.includes("/jobs/new/shortlist") ||
            pathname.includes("/agency")))
    ) {
      toggleShortlistOpen();
      if (ref) {
        (ref as MutableRefObject<HTMLDivElement>).current.style.top = "0";
      }
    }
  }, [numberOfTalent]);

  //this stops the background scroll when the mobile stack is open
  useEffect(() => {
    if (isMobile && typeof window != "undefined" && window.document) {
      shortlistOpen
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "unset");
    }
  }, [shortlistOpen]);

  return showBookCreatives ? (
    <LinkButton
      data-test-id="HeaderDashboardCTA"
      to={"/jobs/new"}
      variant="primaryCta"
      css={{
        background: "$white",
        color: "$black",
      }}
    >
      Browse Talent
      <Icon variant="chevronRight" size={12} css={{ ml: "$4" }} />
    </LinkButton>
  ) : !featureEnabled("job_details_revamp") ? (
    <Box>
      <Box
        css={{
          display: "none",
          "@bp4": {
            display: "initial",
          },
        }}
      >
        {!numberOfTalent ? (
          <Pill
            variant="roundedFilter"
            css={{
              p: "$3 $4",
              borderColor: "$black",
              margin: 0,
            }}
          >
            <Icon variant="checklist" size={18} />
            <Text variant="mini" css={{ ml: "$3" }}>{`${pluralize(
              numberOfTalent,
              "Talent",
            )} shortlisted`}</Text>
          </Pill>
        ) : (
          <DropdownPill
            icon="checklist"
            iconSize={18}
            text={`${pluralize(numberOfTalent, "Talent")} shortlisted`}
            active={activeState}
            noGutter={true}
            caretColor="black"
            caretPosition="right"
            textVariant="mini"
            withCloseDelay
            onCloseCallback={() => {
              !isMobile && toggleShortlistOpen();
            }}
            initiallyOpen={shortlistOpen}
            css={{
              padding: 0,
              margin: 0,
            }}
          >
            <Flex
              data-test-id="ShortlistHeaderButton"
              css={{
                minWidth: "375px",
                display: "none",
                "@bp4": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Flex
                css={{
                  backgroundColor: "$black",
                  p: "$6",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text
                    variant="mini"
                    color="grey3"
                    css={{
                      fontFamily: "$sansWide",
                    }}
                  >
                    my shortlist
                  </Text>
                  <Flex
                    css={{
                      gap: "$3",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      variant="mini"
                      color="white"
                      css={{
                        fontFamily: "$sansWide",
                      }}
                    >{`${pluralize(numberOfTalent, "Talent")}`}</Text>
                    {numberOfTalent && (
                      <Text
                        variant="meta"
                        color="grey2"
                        css={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setTalentUsers([])}
                      >
                        Reset shortlist
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Flex>

              <Box
                css={{
                  p: "$3 $7 $7",
                  maxHeight: "320px",
                  overflow: "scroll",
                  maxWidth: "385px",
                }}
              >
                {pathname.includes("/jobs/new/shortlist") &&
                  showShortlistingReminder && (
                    <Flex
                      css={{
                        height: "68px",
                        width: "330px",
                        mt: "$3",
                        p: "$4",
                        borderRadius: "12px",
                        backgroundColor: "$grey1",
                        gap: "$4",
                      }}
                    >
                      <Icon
                        size={16}
                        variant="excessiveShortlisting"
                        css={{ minWidth: "fit-content", mt: "$2" }}
                      />
                      <Text variant="meta">
                        We recommend shortlisting double the number of talents
                        you need.
                      </Text>
                      <Box
                        onClick={() => {
                          setShowShortlistingReminder(false);
                        }}
                        css={{ minWidth: "fit-content" }}
                      >
                        <Icon size={10} variant="cross" color="grey6" />
                      </Box>
                    </Flex>
                  )}
                <ShortlistVerticals
                  talent={talentUsers}
                  talentVerticals={talentVerticals}
                  isRemovable={(u) =>
                    !!talentUsers.map((t) => t.id).includes(u.id)
                  }
                  onRemove={(u) => removeTalentUser(u.id)}
                  withinShortlistButton={true}
                />
              </Box>
              <Flex
                css={{
                  p: "$0 $4 $4",
                  gap: "$3",
                }}
              >
                {!pathname.includes("/jobs/new/shortlist") ||
                  (!pathname.includes("/jobs/new/agency") && (
                    <Button
                      variant="white"
                      onClick={() => history.push("/jobs/new/shortlist")}
                    >
                      Shortlist more
                    </Button>
                  ))}
                {!!numberOfTalent && (
                  <Button
                    variant="primary"
                    onClick={onContinue}
                    css={{
                      flex: 1,
                    }}
                    data-test-id="ShortlistHeaderButtonContinue"
                  >
                    Continue
                  </Button>
                )}
              </Flex>
            </Flex>
          </DropdownPill>
        )}
      </Box>

      {/* Mobile CTA  */}
      <Pill
        data-test-id="ShortlistHeaderButtonMobile"
        variant="roundedFilter"
        css={{
          margin: 0,
          cursor: "pointer",
          p: "$3 $4",
          borderColor: "$black",
          backgroundColor: activeState ? "$black" : "$white",
          color: activeState ? "$white" : "$black",
          "@bp4": {
            display: "none",
          },
        }}
        onClick={handleButtonClick}
      >
        <Icon
          variant="checklist"
          size={18}
          color={activeState ? "white" : "black"}
        />
        <Text
          variant="buttonText"
          color={activeState ? "white" : "black"}
          css={{ ml: "$3" }}
        >
          {numberOfTalent}
        </Text>
      </Pill>

      <ShortlistMobileStack
        open={shortlistOpen}
        onContinue={onContinue}
        onClose={handleButtonClick}
        showShortlistingReminder={showShortlistingReminder}
        setShowShortlistingReminder={setShowShortlistingReminder}
      />
    </Box>
  ) : (
    <Box>
      <Pill
        data-test-id="ShortlistHeaderButton"
        variant="roundedFilter"
        css={{
          margin: 0,
          cursor: "pointer",
          p: "$3 $4",
          borderColor: "$black",
          backgroundColor: activeState ? "$black" : "$white",
          color: activeState ? "$white" : "$black",
        }}
        onClick={() => toggleShortlistOpen()}
      >
        <Icon
          variant="checklist"
          size={18}
          color={activeState ? "white" : "black"}
        />
        <Text
          variant="mini"
          color={activeState ? "white" : "black"}
          css={{
            ml: "$3",
            "@bp4": {
              display: "none",
            },
          }}
        >
          {numberOfTalent}
        </Text>
        <Text
          variant="mini"
          color={activeState ? "white" : "black"}
          css={{
            ml: "$3",
            display: "none",
            "@bp4": {
              display: "initial",
            },
          }}
        >
          {pluralize(numberOfTalent, "talent")} shortlisted
        </Text>
      </Pill>

      <ShortlistTray
        open={shortlistOpen}
        onContinue={onContinue}
        onClose={() => toggleShortlistOpen()}
      />
    </Box>
  );
});
