import React from "react";
import { capitalize } from "lodash";
import { Box, Flex } from "src/ccl/layout";
import { AssetImage, Icon, Text } from "src/ccl/document";
import { Agency, TalentVertical } from "src/graphql/types";
import { verticalIconMapAlt, verticalMap } from "src/utils/user";
import { Link } from "src/ccl/navigation";
import { Tooltip } from "src/ccl/feedback";

interface VerticalTitleProps {
  vertical: TalentVertical;
  category: string | undefined;
  agency?: Agency;
  creativeType?: string;
}

export const VerticalTitle = ({
  vertical,
  category,
  agency,
  creativeType,
}: VerticalTitleProps) => {
  return (
    <Flex
      css={{
        borderBottom: "1px solid $grey2",
        pb: "$4",
        mb: "$5",
        "@bp3": {
          pb: "$7",
          mb: "$10",
        },
      }}
    >
      {agency?.logo && (
        <Box
          css={{
            width: 44,
            height: 50,
            mr: "$4",
            display: "none",
            "@bp3": { mr: "$5" },
            "@bp1": { display: "block" },
          }}
        >
          <AssetImage
            asset={agency?.logo}
            size={{
              "@initial": {
                width: 44,
                height: 50,
                fit: "clamp",
              },
            }}
            alt={`Logo of ${agency.name}`}
          />
        </Box>
      )}
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Flex css={{ alignItems: "center" }}>
          <Tooltip
            content={capitalize(verticalMap[vertical])}
            css={{ zIndex: "250" }}
            hover="noMobileHover"
          >
            <Icon
              size={30}
              variant={verticalIconMapAlt[vertical]}
              css={{ justifySelf: "flex-start", mr: "$3" }}
            />
          </Tooltip>
          <Text
            variant={{ "@initial": "buttonText", "@bp3": "nh4" }}
            css={{
              mb: "$2",
              textTransform: "capitalize",
            }}
          >
            {creativeType || verticalMap[vertical]}
          </Text>
        </Flex>
        {vertical === TalentVertical.FashionModel && (
          <Flex>
            <Text
              variant="meta"
              css={{
                mr: "$5",
              }}
            >
              Category: <strong>{category}</strong>
            </Text>
            {agency?.name != "Contact" ? (
              <Link
                to={`/agency/${agency?.slug}`}
                css={{ textDecoration: "none" }}
              >
                <Text variant="meta">
                  Added by <strong>{agency?.name}</strong>
                </Text>
              </Link>
            ) : (
              <Text variant="meta">
                Added by <strong>{agency?.name}</strong>
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
