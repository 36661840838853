import React from "react";
import { DropdownPill } from "src/ccl/blocks/pill/DropdownPill";
import { Box, Flex } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { styled } from "src/ccl";
import { RangeInput } from "src/ccl/data-entry/input";

const GraphBar = styled(Box, {
  width: "18px",
  backgroundColor: "$grey2",
  mr: "$1",
});

export const BudgetFilterOptions = ({
  minDayRate,
  maxDayRate,
  setDayRates,
  dayRateDistribution,
  defaultMaximum,
}: {
  minDayRate: number;
  maxDayRate: number;
  setDayRates: (values: [number, number]) => void;
  dayRateDistribution: number[];
  defaultMaximum: number;
}) => {
  const maxDistributionValue = Math.max(...dayRateDistribution);
  return (
    <Box css={{ p: "$4", "@bp2": { p: "$5" } }}>
      <Flex
        css={{
          gap: "$2",

          "@bp2": {
            borderBottom: "1px solid $grey1",
            pb: "$8",
            px: "$9",
          },
        }}
      >
        <Icon
          variant="information"
          size={16}
          color="grey6"
          css={{ my: "auto" }}
        />
        <Text variant="meta" color="grey6">
          Show creatives who fit your budget
        </Text>
      </Flex>
      <Flex
        css={{
          height: "$10",
          mt: "$5",
          mb: "-6px",
          px: "$10",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {dayRateDistribution.map((slice: number, i) => (
          <GraphBar
            key={i}
            css={{
              height: `${(slice / maxDistributionValue) * 100 || 10}%`,
              width: "8%",
            }}
          />
        ))}
      </Flex>
      <Box css={{ mx: "$4" }}>
        <RangeInput
          minimum={0}
          maximum={defaultMaximum}
          values={[minDayRate || 0, maxDayRate || defaultMaximum]}
          onChange={setDayRates}
          sliderPrefix={"£"}
        />
      </Box>
    </Box>
  );
};

export const BudgetFilter = ({
  minDayRate,
  maxDayRate,
  selectedFilter,
  setSelectedFilter,
  ...props
}: {
  minDayRate: number;
  maxDayRate: number;
  setDayRates: (values: [number, number]) => void;
  dayRateDistribution: number[];
  defaultMaximum: number;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
}) => {
  return (
    <DropdownPill
      active={(minDayRate || maxDayRate) !== undefined}
      icon="budgetOutline"
      text="Daily budget"
      withHoverEffect
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
    >
      <BudgetFilterOptions
        minDayRate={minDayRate}
        maxDayRate={maxDayRate}
        {...props}
      />
    </DropdownPill>
  );
};
