import React from "react";
import { Box, ReadMoreBox } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import { ParagraphRenderer } from "src/components/ParagraphRenderer";

interface BiographyProps {
  body: string;
}

export const Biography = ({ body }: BiographyProps) => {
  return (
    <Box>
      {body.length < 500 && (
        <Text variant={"meta"} color="black" css={{ mt: "$5" }}>
          <ParagraphRenderer text={body} />
        </Text>
      )}

      {body.length >= 500 && (
        <Box css={{ mt: "$5" }}>
          <ReadMoreBox
            copy={body}
            charLength={347}
            clickOpenCopy="Read full bio"
            clickCloseCopy="Close full bio"
          />
        </Box>
      )}
    </Box>
  );
};
