import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { FieldInfoText } from "./FieldInfoText";
import options from "src/data/filterOptions.json";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Field, FormikInput, SelectReact } from "src/ccl/data-entry";
import { useStoreModel } from "src/hooks";
import {
  ProfileFormContext,
  SocialMediaInput,
  TalentVertical,
  UpdateTalentProfileInput,
} from "src/graphql/types";
import { Button } from "src/ccl/navigation";
import { SectionHeader } from "src/components/SectionHeader";

interface SocialMediaProps {
  talentProfile: UpdateTalentProfileInput;
  context: ProfileFormContext;
  vertical: TalentVertical;
}

const SocialMedia = ({
  talentProfile,
  context,
  vertical,
}: SocialMediaProps) => {
  const { featureEnabled } = useStoreModel("features");

  const variant = featureEnabled("tpa_updated_creative_upload")
    ? "rebrand"
    : undefined;

  const { setFieldValue } = useFormikContext();

  const formattedOptions = options.social_media_fields.map(
    ({ label, value }) => ({
      label,
      value: value.toUpperCase(),
    }),
  );

  useEffect(() => {
    if (vertical === TalentVertical.Influencer) {
      setFieldValue(
        "talentProfile.socialMediaFields",
        talentProfile.socialMediaFields?.length
          ? talentProfile.socialMediaFields
          : [
              {
                platform: "",
                handle: "",
                followerCount: "",
              },
            ],
      );
    }
  }, [vertical]);

  return (
    <>
      <Box data-step-header-section css={{ mt: "42px" }}>
        <SectionHeader
          step={context === "create" ? "2" : undefined}
          label="Social Media"
          isOptional={vertical !== TalentVertical.Influencer}
        />
      </Box>

      <FieldInfoText
        text="These are shown on the influencer's profile and used to search for creatives. It’s important to keep these details updated."
        fieldVariant="sectionInfo"
      />
      <Grid
        css={{ gridColumns: 1, "@bp2": { gridColumns: 3, columnGap: "$7" } }}
      >
        {talentProfile.socialMediaFields?.map(
          (socialMediaInput: SocialMediaInput, index: number) => (
            <React.Fragment key={index + socialMediaInput.platform}>
              <Box>
                <Field
                  variant="b2Bold"
                  name={`talentProfile.socialMediaFields.${index}.platform`}
                  label="Social media platform"
                  css={{ mt: "$6", mb: "$5" }}
                  labelCss={{ mb: "$5" }}
                >
                  <SelectReact
                    id={`talentProfile.socialMediaFields.${index}.platform`}
                    variant={variant}
                    initialValues={formattedOptions.find(
                      (socialMedia) =>
                        socialMedia.value === socialMediaInput.platform,
                    )}
                    onChange={(socialMedia) =>
                      setFieldValue(
                        "talentProfile.socialMediaFields",
                        talentProfile.socialMediaFields?.map(
                          (s: SocialMediaInput, i: number) =>
                            index === i
                              ? { ...s, platform: socialMedia?.value }
                              : s,
                        ),
                      )
                    }
                    options={formattedOptions.filter(
                      (socialMedia: any) =>
                        !talentProfile.socialMediaFields?.some(
                          (s: SocialMediaInput) =>
                            socialMedia.value === s.platform,
                        ),
                    )}
                    isSearchable
                  />
                </Field>
              </Box>
              <Field
                variant="b2Bold"
                name={`talentProfile.socialMediaFields.${index}.handle`}
                label="Username"
                css={{ mt: "$6", mb: "$5" }}
                labelCss={{ mb: "$5" }}
              >
                <FormikInput
                  name={`talentProfile.socialMediaFields.${index}.handle`}
                  inputPrefix="@"
                  type="text"
                  variant={variant}
                />
              </Field>
              <Field
                variant="b2Bold"
                name={`talentProfile.socialMediaFields.${index}.followerCount`}
                label="No. of followers"
                css={{ mt: "$6", mb: "$5" }}
                labelCss={{ mb: "$5" }}
              >
                <Flex
                  css={{
                    gap: "$7",
                    flexDirection: "column",
                    alignItems: "start",
                    position: "relative",
                    "@bp2": {
                      flexDirection: "row",
                      alignItems: "center",
                    },
                  }}
                >
                  <Box css={{ width: "100%" }}>
                    <FormikInput
                      name={`talentProfile.socialMediaFields.${index}.followerCount`}
                      type="number"
                      variant={variant}
                    />
                  </Box>
                  <Button
                    css={{
                      "@bp2": {
                        position: "absolute",
                        right: "-50px",
                      },
                    }}
                    variant="circle"
                    data-test-id="RemoveSocialMedia"
                    onClick={() => {
                      if (
                        vertical === TalentVertical.Influencer &&
                        talentProfile.socialMediaFields?.length === 1
                      ) {
                        return;
                      }
                      setFieldValue(
                        "talentProfile.socialMediaFields",
                        talentProfile.socialMediaFields?.filter(
                          (s: SocialMediaInput, i: number) => index !== i,
                        ),
                      );
                    }}
                  >
                    <Icon
                      variant="trash"
                      size={12}
                      color="grey6"
                      css={{ my: "auto" }}
                    />
                  </Button>
                </Flex>
              </Field>
            </React.Fragment>
          ),
        )}

        {talentProfile.socialMediaFields &&
          talentProfile.socialMediaFields?.length < formattedOptions.length && (
            <Box>
              <Button
                data-test-id="AddSocialMedia"
                variant="unstyled"
                onClick={() => {
                  if (!talentProfile.socialMediaFields) {
                    talentProfile.socialMediaFields = [];
                  }

                  const data: any = {
                    platform: "",
                    handle: "",
                    followerCount: "",
                  };

                  talentProfile.socialMediaFields.push(data);

                  setFieldValue(
                    "talentProfile.socialMediaFields",
                    talentProfile.socialMediaFields,
                  );
                }}
                css={{
                  mt: "25px",
                }}
              >
                <Flex css={{ gap: "$3" }}>
                  <Icon variant="plusCircle" css={{ my: "auto" }} />
                  <Text
                    css={{
                      color: "$grey5",
                      textTransform: "none",
                      fontWeight: "$medium",
                    }}
                  >
                    Add social media
                  </Text>
                </Flex>
              </Button>
            </Box>
          )}
      </Grid>
    </>
  );
};

export default SocialMedia;
