import React, { useState } from "react";
import { truncate } from "lodash";
import type * as Stitches from "@stitches/react";
import { Icon, Text } from "src/ccl/document";
import { ParagraphRenderer } from "src/components";
import { Flex } from "src/ccl/layout";
import { mergeCss } from "src/ccl";

interface ReadMoreBoxProps {
  copy: string;
  charLength: number;
  clickOpenCopy: string;
  clickCloseCopy: string;
  variant?: "dark";
  expandedCss?: Stitches.CSS;
}

export const ReadMoreBox = ({
  copy,
  charLength,
  clickOpenCopy = "Read more",
  clickCloseCopy = "Read less",
  variant,
  expandedCss = {},
}: ReadMoreBoxProps) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Text variant="meta">
        <ParagraphRenderer
          text={
            isReadMore
              ? truncate(copy, {
                  length: charLength,
                  separator: /,? +/,
                })
              : copy
          }
          css={mergeCss(
            {
              color: variant === "dark" ? "$white" : "$black",
              fontFamily: "$sans",
              mb: "$6",
            },
            expandedCss,
          )}
        />
      </Text>

      <Flex
        css={{
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggleReadMore}
      >
        <Icon
          variant={isReadMore ? "chevronDown" : "chevronUp"}
          size={14}
          color={variant === "dark" ? "white" : "black"}
        />
        <Text
          css={{
            textDecoration: "underline",
            color: variant === "dark" ? "white" : "black",
            ml: "$3",
          }}
        >
          {isReadMore ? clickOpenCopy : clickCloseCopy}
        </Text>
      </Flex>
    </>
  );
};
