import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Carousel } from "src/ccl/blocks";
import {
  DatePicker,
  Field,
  FormikCheckbox,
  FormikInput,
  FormikTextarea,
  FormikToggle,
  Radio,
  SelectReact,
} from "src/ccl/data-entry";
import { AvailableIcon, Icon, InfoBanner, Text } from "src/ccl/document";
import { Box, Flex } from "src/ccl/layout";
import { DeliverablesCheckboxes } from "src/components";
import {
  Component,
  ComponentOption,
  CreateJobVerticalInput,
  CreatePackageVerticalInput,
  HeadcountEstimate,
  InputMaybe,
  JobKind,
  NudityInvolvement,
  PackageVertical,
  TalentVertical,
  Timeslot,
  TimeslotInput,
} from "src/graphql/types";
import { formatDate } from "src/utils/dates";
import {
  JobHelpBox,
  JobLengthPlaceholder,
  headcountEstimateMap,
} from "src/utils/job";
import { sentenceCase, sentenceCaseOptions } from "src/utils/lang";
import {
  jobTypeIconMap,
  jobTypeMap,
  verticalIconBackgroundMap,
  verticalIconMapAltOutline,
  verticalMap,
} from "src/utils/user";
import {
  DatePickerProps,
  DatePickerValues,
} from "src/components/JobDetailsForm/DatePicker";
import {
  DeckFileUpload,
  DeckFileUploadProps,
} from "src/components/JobDetailsForm/DeckFileUpload";
import {
  PaymentCalculator,
  PaymentCalculatorProps,
} from "src/components/JobDetailsForm/PaymentCalculator";

export const VirtualEventToggle = ({
  secondaryLabel,
}: {
  secondaryLabel?: string;
}) => (
  <Field label="What kind of job is it?" secondaryLabel={secondaryLabel}>
    <FormikToggle
      name="virtual"
      textChecked="Virtual job"
      textUnchecked="Onsite"
      iconChecked={<Icon variant="virtual" size={16} />}
      iconUnchecked={<Icon variant="onsite" size={16} />}
    />
  </Field>
);

export const VirtualEventCheckbox = ({
  secondaryLabel,
}: {
  secondaryLabel?: string;
}) => (
  <Field label="" secondaryLabel={secondaryLabel}>
    <Flex css={{ gap: "$3", alignItems: "center", mb: "$7" }}>
      <FormikCheckbox
        name="virtual"
        variant="rebrand"
        css={{ height: "18px", width: "18px" }}
      />
      <Text>Is this a virtual job?</Text>
    </Flex>
  </Field>
);

export const NameField = ({
  variant,
  value,
}: {
  variant?: "rebrand";
  value?: string;
}) => (
  <Field label={"What's the job name?"} name="name">
    <FormikInput
      name="name"
      placeholder="e.g. Promo shoot for new leisurewear"
      type="text"
      variant={variant}
      value={value}
    />
  </Field>
);

interface TypeFieldProps {
  selectOptions?: ComponentOption[];
  values: {
    kind?: InputMaybe<JobKind>;
  };
  setFieldValue: (field: string, value: string) => void;
  secondaryLabel?: string;
  setFocusedHelpBox?: (box: JobHelpBox) => void;
  helpBox?: JobHelpBox;
  variant?: "rebrand";
}

export const TypeField = ({
  selectOptions = [],
  setFieldValue,
  values,
  secondaryLabel,
  setFocusedHelpBox,
  helpBox,
  variant,
}: TypeFieldProps) => {
  return (
    <Field
      label="What’s the job type?"
      name="kind"
      secondaryLabel={secondaryLabel}
      css={{ maxWidth: "95vw", pt: "$6" }}
    >
      {variant === "rebrand" ? (
        <>
          <Carousel
            gap={36}
            ArrowCss={{
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              boxShadow: "0px 0px 2px grey",
            }}
          >
            {selectOptions
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option) => (
                <Flex
                  css={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "75px",
                    width: "fit-content",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    mt: "$4",
                  }}
                  onClick={() => setFieldValue("kind", option.value)}
                  key={`option-${option.value}`}
                >
                  <Icon
                    variant={jobTypeIconMap[option.value]}
                    size={40}
                    css={{
                      flexShrink: 1,
                      marginBottom: "5px",
                    }}
                  />
                  <Text
                    css={{
                      whiteSpace: "nowrap",
                      pb: "$3",
                      px: values.kind === option.value ? "0px" : "1px",
                      fontWeight: values.kind === option.value ? "600" : "300",
                    }}
                    weight={values.kind === option.value ? "bold" : undefined}
                    color={values.kind === option.value ? "black" : "grey6"}
                  >
                    {jobTypeMap[option.value] || option.label}
                  </Text>
                  <Box
                    css={{
                      width: "72px",
                      backgroundColor:
                        values.kind === option.value ? "$black" : "$white",
                      borderRadius: "4px",
                      height: "2px",
                    }}
                  />
                </Flex>
              ))}
          </Carousel>
        </>
      ) : (
        <SelectReact
          id="TypeField"
          value={
            values.kind
              ? selectOptions.find((o) => o.value === values.kind)
              : undefined
          }
          placeholder={"e.g. Ecom, Campaign"}
          options={sentenceCaseOptions(selectOptions)}
          onChange={(option) =>
            setFieldValue("kind", option?.value || JobKind.Other)
          }
          onFocus={() => {
            helpBox && setFocusedHelpBox
              ? setFocusedHelpBox(helpBox)
              : () => {};
          }}
        />
      )}
    </Field>
  );
};

interface HeadcountEstimateFieldProps {
  values: {
    headcountEstimate?: InputMaybe<HeadcountEstimate>;
  };
  setFieldValue: (field: string, value: string) => void;
  secondaryLabel?: string;
  secondaryLabelbelowTitle?: string;
}

export const HeadcountEstimateField = ({
  setFieldValue,
  values,
  secondaryLabel,
  secondaryLabelbelowTitle,
}: HeadcountEstimateFieldProps) => {
  const formattedOptions = Object.entries(headcountEstimateMap).map(
    ([label, value]) => ({
      label: value,
      value: label,
    }),
  );

  return (
    <Field
      label="How many people on set?"
      name="headcountEstimate"
      secondaryLabel={secondaryLabel}
      css={{ maxWidth: "585px" }}
    >
      {!isNil(secondaryLabelbelowTitle) && (
        <Text variant="meta" color="grey6">
          {secondaryLabelbelowTitle}
        </Text>
      )}
      <SelectReact
        id="HeadcountEstimateField"
        value={
          values.headcountEstimate
            ? formattedOptions.find(
                (o) => o.value === values?.headcountEstimate,
              )
            : undefined
        }
        placeholder={"0-5"}
        options={formattedOptions}
        onChange={(option) =>
          setFieldValue(
            "headcountEstimate",
            option?.value || HeadcountEstimate.ZeroToFive,
          )
        }
        variant="rebrand"
      />
    </Field>
  );
};

interface TravelExpenseCoverFieldProps {
  secondaryLabel?: string;
  value?: number;
  travelExpenseCovered: string;
  setTravelExpenseCovered: (display: TravelExpenseInputValue) => void;
  hasMaxTravelBudget?: boolean;
  setHasMaxTravelBudget?: (display: boolean) => void;
}

type TravelExpenseInputValue = "flex" | "none";

export const TravelExpenseCoverField = ({
  value,
  travelExpenseCovered,
  setTravelExpenseCovered,
  hasMaxTravelBudget,
  setHasMaxTravelBudget,
}: TravelExpenseCoverFieldProps) => {
  return (
    <>
      <Field label="Are travel expenses covered?" css={{ maxWidth: "300px" }}>
        <Flex css={{ flexDirection: "column", gap: "$5" }}>
          <Flex css={{ alignItems: "center", gap: "15px" }}>
            <Radio
              variant="rebrand"
              size="s"
              id={"yes"}
              value="flex"
              onChange={(e) =>
                setTravelExpenseCovered(
                  e.target.value as TravelExpenseInputValue,
                )
              }
              checked={travelExpenseCovered === "flex"}
              name="travel-expense-cover-value"
            />
            <Text> Yes</Text>
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              minHeight: "100px",
              gap: 16,
              display: travelExpenseCovered,
            }}
          >
            <Field
              label="Do you have a max budget for travel expenses?"
              labelCss={{
                fontSize: "$14",
                color: "#6C6C6C",
                fontWeight: "$regular",
              }}
              css={{ maxWidth: "300px" }}
            >
              <Box css={{ borderLeft: "4px solid $grey3", pl: "$5" }}>
                <Flex css={{ flexDirection: "column", gap: "$5" }}>
                  <Flex css={{ alignItems: "center", gap: "15px" }}>
                    <Radio
                      variant="rebrand"
                      size="s"
                      id={"yes"}
                      value="true"
                      onChange={(e) => {
                        if (setHasMaxTravelBudget) {
                          setHasMaxTravelBudget(e.target.value === "true");
                        }
                      }}
                      name="has-max-travel-budget-value"
                    />
                    <Text> Yes</Text>
                  </Flex>
                  {hasMaxTravelBudget && (
                    <Field
                      label="How much is the max fee you can cover per talent?"
                      labelCss={{
                        fontSize: "$14",
                        color: "#6C6C6C",
                        fontWeight: "$regular",
                      }}
                      name="travelExpenseCover"
                    >
                      <FormikInput
                        name="travelExpenseCover"
                        type="number"
                        inputPrefix="£"
                        variant="rebrand"
                        value={value}
                        css={{ width: "322px" }}
                      />
                    </Field>
                  )}
                  <Flex css={{ alignItems: "center", gap: "15px" }}>
                    <Radio
                      variant="rebrand"
                      size="s"
                      id={"no"}
                      value="false"
                      onChange={(e) => {
                        if (setHasMaxTravelBudget) {
                          setHasMaxTravelBudget(e.target.value === "true");
                        }
                      }}
                      name="has-max-travel-budget-value"
                    />
                    <Text>No, I am willing to cover in full</Text>
                  </Flex>
                </Flex>
              </Box>
            </Field>
          </Flex>
          <Flex css={{ alignItems: "center", gap: "15px" }}>
            <Radio
              variant="rebrand"
              size="s"
              id={"no"}
              value="none"
              onChange={(e) =>
                setTravelExpenseCovered(
                  e.target.value as TravelExpenseInputValue,
                )
              }
              name="travel-expense-cover-value"
            />
            <Text>No</Text>
          </Flex>
        </Flex>
      </Field>
    </>
  );
};

interface CateringProvidedFieldProps {
  value?: boolean;
  onChange: (value: boolean) => void;
}

export const CateringProvidedField = ({
  value,
  onChange,
}: CateringProvidedFieldProps) => {
  return (
    <Field
      label="Is catering provided?"
      name="cateringProvided"
      css={{ maxWidth: "300px" }}
    >
      <Flex css={{ flexDirection: "column", gap: "$5" }}>
        <Flex css={{ alignItems: "center", gap: "15px" }}>
          <Radio
            variant="rebrand"
            size="s"
            id={"yes"}
            onChange={() => onChange(true)}
            checked={value === true}
            name="catering-provided-value"
          />
          <Text> Yes</Text>
        </Flex>
        <Flex css={{ alignItems: "center", gap: "15px" }}>
          <Radio
            variant="rebrand"
            size="s"
            id={"no"}
            value="none"
            onChange={() => onChange(false)}
            checked={value === false}
            name="catering-provided-value"
          />
          <Text>No</Text>
        </Flex>
      </Flex>
    </Field>
  );
};

interface SelfTapeRequiredFieldProps {
  value?: boolean;
  onChange: (value: boolean) => void;
}

export const SelfTapeRequiredField = ({
  value,
  onChange,
}: SelfTapeRequiredFieldProps) => {
  return (
    <Field
      label="Is a self-tape required?"
      name="selfTapeRequired"
      css={{ maxWidth: "300px" }}
    >
      <Flex css={{ flexDirection: "column", gap: "$5" }}>
        <Flex css={{ alignItems: "center", gap: "15px" }}>
          <Radio
            variant="rebrand"
            size="s"
            id={"yes"}
            onChange={() => onChange(true)}
            checked={value === true}
            name="self-tape-required-value"
          />
          <Text> Yes</Text>
        </Flex>
        <Flex css={{ alignItems: "center", gap: "15px" }}>
          <Radio
            variant="rebrand"
            size="s"
            id={"no"}
            value="none"
            onChange={() => onChange(false)}
            checked={value === false}
            name="self-tape-required-value"
          />
          <Text>No</Text>
        </Flex>
      </Flex>
    </Field>
  );
};

const asSelectOptions = (values: string[]) =>
  values.map((v) => ({
    label: v,
    value: v,
  }));

export const ClientField = ({
  initialValues,
  onChange,
  value,
  secondaryLabel,
  helpBox,
  setFocusedHelpBox,
  variant = undefined,
}: {
  initialValues?: string[];
  value?: string;
  onChange?: (value: string) => void;
  secondaryLabel?: string;
  setFocusedHelpBox?: (box: JobHelpBox) => void;
  helpBox?: JobHelpBox;
  variant?: "rebrand";
}) => {
  const previousBrandOptions = asSelectOptions(initialValues || []);
  return (
    <Field label="Who's it for?" name="brand" secondaryLabel={secondaryLabel}>
      {previousBrandOptions.length && onChange ? (
        <>
          <SelectReact
            id="BrandField"
            creatable
            value={
              value
                ? { label: value as string, value: value as string }
                : undefined
            }
            placeholder="e.g. Nike"
            options={previousBrandOptions}
            onChange={(option) => onChange(option?.value || "")}
            variant={variant}
          />
        </>
      ) : (
        <FormikInput
          name="brand"
          placeholder="e.g. Nike"
          type="text"
          onFocus={() => {
            helpBox && setFocusedHelpBox
              ? setFocusedHelpBox(helpBox)
              : undefined;
          }}
          value={value}
          variant={variant}
        />
      )}
    </Field>
  );
};

interface DatePickerFieldProps {
  onChangeStartDate: (date: Date) => void;
  onChangeEndDate: (date: Date) => void;
  onChangeSpansMultipleDays: (value: boolean) => void;
  disablePastDates?: boolean;
  initialStartDate: Date;
  initialEndDate: Date;
  values: DatePickerValues;
  disabled?: boolean;
}

export const DatePickerField = ({
  onChangeStartDate,
  onChangeEndDate,
  onChangeSpansMultipleDays,
  disablePastDates,
  initialStartDate,
  initialEndDate,
  values,
  disabled,
}: DatePickerFieldProps) => {
  return (
    <>
      <Text weight="bold">When is the job?</Text>
      <Flex css={{ flexDirection: "column", "@bp3": { flexDirection: "row" } }}>
        <Field label="" name="startDate">
          <DatePicker
            disabled={disabled}
            onChange={onChangeStartDate}
            disablePastDates={disablePastDates}
            initialDate={initialStartDate}
          />
        </Field>

        {values.spansMultipleDays && (
          <>
            <Text css={{ mb: "$4", "@bp3": { mx: "$10", mt: "$5" } }}>To </Text>
            <Field label="" name="endDate">
              <DatePicker
                disabled={disabled}
                onChange={onChangeEndDate}
                disablePastDates={disablePastDates}
                initialDate={initialEndDate}
              />
            </Field>
          </>
        )}
      </Flex>
      <Field label="" name="spansMultipleDays">
        <Flex css={{ alignItems: "center", gap: "$3" }}>
          <FormikCheckbox
            css={{ height: "18px", width: "18px" }}
            name="spansMultipleDays"
            onChange={(e) => {
              onChangeSpansMultipleDays(e.target.value !== "true");
            }}
            variant="rebrand"
          />
          <Text> Multi-day job</Text>
        </Flex>
      </Field>
    </>
  );
};

interface LocationFieldValues {
  virtual?: boolean;
  location?: string;
}
interface LocationFieldProps {
  values: LocationFieldValues;
  previousLocations?: string[];
  onChange?: (value: string) => void;
  secondaryLabel?: string;
  setFocusedHelpBox?: (box: JobHelpBox) => void;
  helpBox?: JobHelpBox;
  variant?: "rebrand";
}
export const LocationField = ({
  values,
  previousLocations,
  onChange,
  secondaryLabel,
  helpBox,
  setFocusedHelpBox,
  variant = undefined,
}: LocationFieldProps) => {
  const previousLocationOptions = asSelectOptions(previousLocations || []);
  const value = values.location;
  return !values.virtual ? (
    <Box css={{ pb: "$6" }}>
      <Field
        label="Where will the job take place?"
        name="location"
        secondaryLabel={secondaryLabel}
        css={{ flexDirection: "column" }}
      >
        {variant === "rebrand" && (
          <Text variant="meta" color="grey6">
            Try to be as accurate as possible, although you can always edit job
            details later.
          </Text>
        )}
        {previousLocationOptions.length && onChange ? (
          <>
            <SelectReact
              id="LocationField"
              boxCss={{ mb: -16 }}
              creatable
              value={value ? { label: value, value: value } : undefined}
              placeholder="e.g. London"
              options={previousLocationOptions}
              onChange={(option) => onChange(option?.value || "")}
              variant={variant}
            />
          </>
        ) : (
          <FormikInput
            name="location"
            placeholder="e.g. London"
            type="text"
            onFocus={() => {
              helpBox && setFocusedHelpBox
                ? setFocusedHelpBox(helpBox)
                : undefined;
            }}
            variant={variant}
          />
        )}
      </Field>
    </Box>
  ) : null;
};

const DescriptionFieldPlaceHolder =
  "What's the job and concept?\nAre you covering travel expenses?\nAre other creatives involved?";

const RebrandDescriptionFieldPlaceholder = "Give as much detail as possible";

interface DescriptionFieldProps {
  setFocusedHelpBox: (box: JobHelpBox) => void;
  secondaryLabel?: string;
  helpBox?: JobHelpBox;
  variant?: "rebrand";
  value?: string;
}
export const DescriptionField = ({
  setFocusedHelpBox,
  secondaryLabel,
  helpBox = "DescriptionHelperBox",
  variant = undefined,
}: DescriptionFieldProps) => {
  return (
    <>
      <Field
        label={`Job description ${variant === "rebrand" ? " and concept" : ""}`}
        name="description"
        secondaryLabel={secondaryLabel}
      >
        <FormikTextarea
          name="description"
          placeholder={
            variant === "rebrand"
              ? RebrandDescriptionFieldPlaceholder
              : DescriptionFieldPlaceHolder
          }
          rows={10}
          onFocus={() => setFocusedHelpBox(helpBox)}
          variant={variant}
        />
      </Field>
    </>
  );
};

interface NudityTypeFieldProps {
  values: {
    nudityType?: InputMaybe<NudityInvolvement>;
  };
  selectOptions: ComponentOption[];
  setFieldValue: (field: string, value: string) => void;
  showNudityTypeInput: string;
  setShowNudityTypeInput: (display: string) => void;
}

export const NudityTypeField = ({
  values,
  selectOptions,
  setFieldValue,
  showNudityTypeInput,
  setShowNudityTypeInput,
}: NudityTypeFieldProps) => {
  return (
    <>
      <Field label="Does the shoot involve nudity?" name="nudityType">
        <Text variant="meta" color="grey6" css={{ lineHeight: "19.5px" }}>
          We&apos;re likely to reject jobs involving nudity, unless they&apos;re
          editorial shoots for trusted bookers.
        </Text>
        <Flex css={{ flexDirection: "column", gap: "$5", mt: "$3" }}>
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Radio
              variant="rebrand"
              size="s"
              id={"yes"}
              value="flex"
              onChange={(e) => setShowNudityTypeInput(e.target.value)}
              name="nudity-type-value"
              checked={showNudityTypeInput === "flex"}
            />
            <Text> Yes</Text>
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              display: showNudityTypeInput,
            }}
          >
            <Text variant="meta" color="grey4">
              What kind of nudity is involved
            </Text>
            <Box css={{ borderLeft: "4px solid $grey3", pl: "$4", mt: "$3" }}>
              {selectOptions.map((option) => (
                <Flex
                  css={{
                    pb: "$3",
                    "&:last-child": {
                      pb: "$0",
                    },
                  }}
                  key={`option-${option.value}`}
                >
                  <Radio
                    variant="rebrand"
                    size="s"
                    onChange={(e) =>
                      setFieldValue("nudityType", e.target.value)
                    }
                    value={option.value}
                    id={`nudity-type-${option.value}`}
                    name={"nudity-type-selection"}
                    checked={option.value === values.nudityType}
                  />
                  <Text css={{ ml: "$3" }}>
                    {sentenceCase(option.label.toLowerCase())}
                  </Text>
                </Flex>
              ))}
            </Box>
          </Flex>
          <Flex css={{ alignItems: "center", gap: "$3" }}>
            <Radio
              variant="rebrand"
              size="s"
              id={"no"}
              value="none"
              onChange={(e) => setShowNudityTypeInput(e.target.value)}
              name="nudity-type-value"
            />
            <Text>No</Text>
          </Flex>
        </Flex>
      </Field>
    </>
  );
};

interface AdditionalNotesFieldProps {
  variant?: "rebrand";
  value?: string;
}
export const AdditionalNotesField = ({
  variant = undefined,
}: AdditionalNotesFieldProps) => {
  return (
    <>
      <Field
        label="Additional notes"
        name="additionalNotes"
        secondaryLabel="(Optional)"
      >
        <FormikTextarea
          name="additionalNotes"
          placeholder={RebrandDescriptionFieldPlaceholder}
          rows={10}
          variant={variant}
        />
      </Field>
    </>
  );
};

interface UsageFieldProps {
  setFocusedHelpBox: (box: JobHelpBox) => void;
  secondaryLabel?: string;
  helpBox?: JobHelpBox;
}
export const UsageField = ({
  setFocusedHelpBox,
  secondaryLabel,
  helpBox = "UsageHelperBox",
}: UsageFieldProps) => (
  <>
    <Field
      variant="b2Bold"
      label="Usage"
      name="usage"
      secondaryLabel={secondaryLabel}
    >
      <FormikTextarea
        variant="rebrand"
        name="usage"
        placeholder="e.g. UK website and social media for 3 months"
        onFocus={() => setFocusedHelpBox(helpBox)}
      />
    </Field>
  </>
);

interface JobLengthFieldValues {
  jobLength?: string;
}
interface JobLengthFieldProps {
  values: JobLengthFieldValues;
  label?: string;
  name?: string;
  secondaryLabel?: string;
}
export const JobLengthField = ({
  label,
  values,
  name = "jobLength",
  secondaryLabel,
}: JobLengthFieldProps) => (
  <Field label={label || ""} name={name} secondaryLabel={secondaryLabel}>
    <FormikInput
      name={name}
      placeholder={JobLengthPlaceholder}
      value={values.jobLength || ""}
      type="text"
    />
    <InfoBanner color="grey6" variant="tightCentered">
      <Text variant="meta" color="grey6">
        Multiple days or just a few hours? Provide your best estimate
      </Text>
    </InfoBanner>
  </Field>
);

interface FeeEstimateFieldValues {
  budget?: number;
}

interface LegacyFeeEstimateFieldProps {
  setFocusedHelpBox: (box: JobHelpBox) => void;
  vertical: TalentVertical;
  values: FeeEstimateFieldValues;
  name?: string;
  label?: string;
  secondaryLabel?: string;
}
export const LegacyFeeEstimateField = ({
  setFocusedHelpBox,
  vertical,
  values,
  name = "budget",
  label = "Fee estimate",
  secondaryLabel,
}: LegacyFeeEstimateFieldProps) => (
  <>
    <Field label={label} name={name} secondaryLabel={secondaryLabel}>
      <FormikInput
        value={
          values.budget !== undefined && values.budget !== null
            ? values.budget
            : ""
        }
        name={name}
        type="number"
        inputPrefix="£"
        onFocus={() => setFocusedHelpBox("PayHelperBox")}
        onWheel={(event) => event.currentTarget.blur()}
      />
      <InfoBanner color="grey6" variant="tightCentered">
        <Text variant="meta" color="grey6">
          {`You can change this after confirming details with your ${verticalMap[vertical]}`}
        </Text>
      </InfoBanner>
    </Field>
  </>
);

interface FeeEstimateFieldProps {
  vertical: TalentVertical;
  icon?: AvailableIcon;
  values?:
    | CreateJobVerticalInput
    | CreatePackageVerticalInput
    | PackageVertical;
  name?: string;
  suffix?: string;
  jobVerticalCount?: Record<string, number>;
}
export const FeeEstimateField = ({
  vertical,
  values,
  name = "budget",
  jobVerticalCount,
}: FeeEstimateFieldProps) => {
  const mobileSuffix = (
    vertical === TalentVertical.Hmua
      ? "per hmua"
      : `per ${verticalMap[vertical]}`
  ).toUpperCase();
  return (
    <Flex
      css={{
        alignItems: "flex-start",
      }}
    >
      <Flex
        css={{
          backgroundColor: `$${verticalIconBackgroundMap[vertical]}`,
          p: "$5",
          borderRadius: "$round",
          alignItems: "center",
          position: "relative",
          mr: "$5",
        }}
      >
        <Icon variant={verticalIconMapAltOutline[vertical]} size={24} />
        {jobVerticalCount && (
          <Flex
            data-test-id={`jobVerticalCount-${vertical}`}
            css={{
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: "-3px",
              right: "-2px",
              width: "$6",
              height: "$6",
              backgroundColor: "white",
              borderRadius: "50%",
              borderColor: "$grey2",
              borderWidth: "1px",
              borderStyle: "solid",
              fontSize: "$9",
              fontWeight: "$bold",
            }}
          >
            {jobVerticalCount[vertical]}
          </Flex>
        )}
      </Flex>

      <Field name={name}>
        <FormikInput
          variant="rebrand"
          name={name}
          value={(values && values.budget?.toString()) || "0"}
          type="number"
          inputPrefix="£"
          prefixCss={{
            color: "$black",
          }}
          inputSuffix={
            <>
              <Text
                variant="mini"
                css={{
                  "@bp1": {
                    display: "none",
                  },
                }}
              >
                {mobileSuffix}
              </Text>
              <Text
                variant="mini"
                css={{
                  display: "none",
                  "@bp1": {
                    display: "initial",
                  },
                }}
              >
                {`per ${verticalMap[vertical]}`.toUpperCase()}
              </Text>
            </>
          }
          onWheel={(event) => event.currentTarget.blur()}
          css={{
            "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "-moz-appearance": "none",

            minWidth: "270px",
            "@bp1": {
              minWidth: "325px",
            },
          }}
        />
      </Field>
    </Flex>
  );
};

interface CustomJobDateProps {
  name: string;
  timeslot: Timeslot;
  setFieldValue: (name: string, value: Date) => void;
  customised?: boolean;
  markJobTimeAsCustom?: () => void;
  disabled?: boolean;
  jobVerticals?: CreateJobVerticalInput[] | PackageVertical[];
  context?: "custom" | "standard";
  tsIndex?: number;
  calendarPosition?: "top" | "bottom";
  startDate: Date;
  endDate: Date;
}

export const CustomJobDateField = ({
  name,
  timeslot,
  setFieldValue,
  markJobTimeAsCustom,
  jobVerticals,
  context,
  tsIndex,
  calendarPosition = "top",
  startDate,
  endDate,
}: CustomJobDateProps) => {
  const handleStandardChange = (
    context: "standard",
    field: "to" | "from",
    newDate: Date,
  ) => {
    if (context === "standard" && jobVerticals) {
      for (let i = 0; i < jobVerticals.length; i++) {
        setFieldValue(
          `jobVerticals.${i}.timeslots.${tsIndex}.${field}`,
          newDate,
        );
      }
    }
  };

  const handleChange = (value: Date, field: "to" | "from") => {
    const newDate = new Date(timeslot[field]);

    newDate.setDate(value.getDate());
    newDate.setMonth(value.getMonth());
    newDate.setFullYear(value.getFullYear());

    setFieldValue(`${name}.${field}`, newDate);
    if (context === "standard") {
      handleStandardChange(context, field, newDate);
    } else if (markJobTimeAsCustom) {
      markJobTimeAsCustom();
    }
  };

  useEffect(() => {
    handleChange(startDate, "from");
    handleChange(endDate, "to");
  }, [startDate, endDate]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        "@bp1": {
          flexDirection: "row",
          alignItems: "center",
        },
      }}
    >
      <DatePicker
        rangeDate={true}
        calendarPosition={calendarPosition}
        disabled={false}
        onChange={(date) => handleChange(date, "from")}
        disablePastDates={true}
        initialDate={startDate}
        startDate={startDate}
        endDate={endDate}
      />
      <Text css={{ px: "$2" }} weight="bold">
        To
      </Text>
      <DatePicker
        rangeDate={true}
        calendarPosition={calendarPosition}
        disabled={false}
        onChange={(date) => handleChange(date, "to")}
        disablePastDates={true}
        initialDate={endDate}
        startDate={startDate}
        endDate={endDate}
      />
    </Flex>
  );
};

interface CustomJobTimeProps {
  name: string;
  timeslot: Timeslot;
  setFieldValue: (name: string, value: Date) => void;
  customised?: boolean;
  markJobTimeAsCustom?: () => void;
  disabled?: boolean;
  jobVerticals?: CreateJobVerticalInput[] | PackageVertical[];
  context?: "custom" | "standard";
  tsIndex?: number;
  showDate?: boolean;
}
export const CustomJobTimeField = ({
  name,
  timeslot,
  setFieldValue,
  markJobTimeAsCustom,
  customised,
  disabled,
  jobVerticals,
  context,
  tsIndex,
  showDate,
}: CustomJobTimeProps) => {
  const handleStandardChange = (
    context: "standard",
    field: "to" | "from",
    newTime: any,
  ) => {
    if (context === "standard" && jobVerticals) {
      for (let i = 0; i < jobVerticals.length; i++) {
        setFieldValue(
          `jobVerticals.${i}.timeslots.${tsIndex}.${field}`,
          newTime,
        );
      }
    }
  };

  const handleChange = (value: string, field: "to" | "from") => {
    const [hours, minutes] = value.split(":").map((s) => parseInt(s));
    const newTime = new Date(timeslot[field]);

    // When user is deleting in formik input type time
    // it will return an invalid date time thus making the
    // set time an invalid date time making the app crash
    // Fix would be to reset time to 10am/8pm when user is trying to delete
    newTime.setHours(hours || (field === "from" ? 10 : 20));
    newTime.setMinutes(minutes || 0);

    setFieldValue(`${name}.${field}`, newTime);
    if (context === "standard") {
      handleStandardChange(context, field, newTime);
    } else if (markJobTimeAsCustom) {
      markJobTimeAsCustom();
    }
  };

  return (
    <Flex
      css={{
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",

        "@bp1": { flexDirection: "row" },
      }}
    >
      <Field css={{ mb: 0 }} name={name}>
        <Flex
          css={{
            minWidth: "270px",
            "@bp1": {
              minWidth: "325px",
            },
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
            p: "$5",
            height: "56px",
            border: "2px solid $grey3",
            "&:placeholder-shown": {
              border: "2px solid $grey2",
              "&:hover": {
                border: "2px solid $grey4",
              },
            },
            "&:hover": {
              border: "px solid $grey4",
            },
            ...(disabled && { backgroundColor: "$grey1" }),
          }}
        >
          {showDate && (
            <Text variant="mini">
              {formatDate(timeslot.from, "dd/MM/yy").toString()}
            </Text>
          )}
          {customised ? (
            <Text variant="body">Custom</Text>
          ) : (
            <Flex
              css={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Flex css={{ alignItems: "center" }}>
                <FormikInput
                  type={"time"}
                  name={`${name}.from`}
                  value={formatDate(timeslot.from, "HH:mm")}
                  onChange={(e) => {
                    handleChange(e.target.value, "from");
                  }}
                  css={{
                    border: "none",
                    minWidth: "0px",
                    cursor: "pointer",
                    pr: "0px",
                    "&::-webkit-calendar-picker-indicator": {
                      display: "none",
                      background: "none",
                    },
                  }}
                  disabled={disabled}
                />
                <Text css={{ pl: "$4" }}>-</Text>
                <FormikInput
                  type={"time"}
                  name={`${name}.to`}
                  value={formatDate(timeslot.to, "HH:mm")}
                  onChange={(e) => handleChange(e.target.value, "to")}
                  css={{
                    border: "none",
                    minWidth: "0px",
                    cursor: "pointer",
                    pr: "0px",

                    "&::-webkit-calendar-picker-indicator": {
                      display: "none",
                      background: "none",
                    },
                  }}
                  disabled={disabled}
                />
              </Flex>
              <Icon
                variant="time"
                size={14}
                color="grey5"
                css={{
                  ml: "$3",
                }}
              />
            </Flex>
          )}
        </Flex>
      </Field>
    </Flex>
  );
};

interface DeliverableFieldValues {
  deliverables?: string[];
}
interface DeliverablesFieldProps {
  values: DeliverableFieldValues;
  checkboxOptions?: string[];
  setFieldValue: (field: string, value: string[]) => void;
  name?: string;
  vertical: TalentVertical;
  secondaryLabel?: string;
}
export const DeliverablesField = ({
  values,
  checkboxOptions = [],
  setFieldValue,
  name,
  vertical,
  secondaryLabel,
}: DeliverablesFieldProps) => (
  <Box css={{ mb: "$3" }} data-test-id="CJDetailsDeliverables">
    <Flex css={{ justifyContent: "space-between" }}>
      <Text
        css={{
          fontWeight: "bold",
          mb: "$5",
        }}
      >
        Deliverables
      </Text>
      {secondaryLabel && (
        <Text variant="meta" color="grey6">
          {secondaryLabel}
        </Text>
      )}
    </Flex>

    <DeliverablesCheckboxes
      checkboxOptions={checkboxOptions}
      checkedValues={values.deliverables || []}
      onChange={(newValues) => {
        setFieldValue(name ? name : "deliverables", newValues);
      }}
      vertical={vertical}
    />
  </Box>
);

export const FullLocationField = () => (
  <Box css={{ mb: "$3" }} data-test-id="CJDetailsFullLocationField">
    <Field name="addressLine1" label="Address Line 1">
      <FormikInput name="addressLine1" type="text" />
    </Field>
    <Field name="addressLine2" label="Address Line 2">
      <FormikInput name="addressLine2" type="text" />
    </Field>
    <Field name="city" label="City">
      <FormikInput name="city" type="text" />
    </Field>
    <Field name="country" label="Country">
      <FormikInput name="country" type="text" />
    </Field>
    <Field name="postcode" label="Postcode">
      <FormikInput name="postcode" type="text" />
    </Field>
  </Box>
);

interface TalentRequiredFieldValues {
  talentRequired?: number;
}
interface TalentRequiredFieldProps {
  values: TalentRequiredFieldValues;
  name?: string;
  label?: string;
  secondaryLabel?: string;
}
export const TalentRequiredField = ({
  values,
  name = "talentRequired",
  label = "Talent required",
  secondaryLabel,
}: TalentRequiredFieldProps) => (
  <Field label={label} name={name} secondaryLabel={secondaryLabel}>
    <FormikInput
      type="number"
      name={name}
      value={values?.talentRequired || ""}
      onWheel={(event) => event.currentTarget.blur()}
    />
    <InfoBanner color="grey6" variant="tightCentered">
      <Text variant="meta" color="grey6">
        You can change this right up until you confirm the job
      </Text>
    </InfoBanner>
  </Field>
);

interface VerticalDetailsFieldProps {
  jobVerticals: CreateJobVerticalInput[] | PackageVertical[];
  jobVerticalValues: InputMaybe<CreateJobVerticalInput[]> | PackageVertical[];
  hasDuration?: boolean;
}

export const VerticalDetailsField = ({
  jobVerticalValues,
  jobVerticals,
  hasDuration = false,
}: VerticalDetailsFieldProps) => (
  <Box>
    <Flex css={{ gap: "$6", mt: "$5", mb: "$3" }}>
      <Text
        variant="body"
        css={{
          "@bp2": {
            width: "269px",
          },
          fontWeight: "$bold",
        }}
      >
        How many Talents do you need?
      </Text>
      {hasDuration && (
        <Text
          variant="body"
          css={{
            fontWeight: "$bold",
            display: "none",
            "@bp2": {
              display: "block",
              width: "269px",
            },
          }}
        >
          How long for?
        </Text>
      )}
    </Flex>

    <Flex css={{ flexDirection: "column" }}>
      {jobVerticals.map((jv, index) => {
        const talentRequiredValue =
          jobVerticalValues && jobVerticalValues[index]?.talentRequired;
        const jobLengthValue =
          jobVerticalValues && jobVerticalValues[index]?.jobLength;
        const pluralize =
          talentRequiredValue === 0 ||
          (talentRequiredValue && talentRequiredValue > 1);

        return (
          <Flex
            css={{
              gap: "$7",
              flexDirection: "column",
              "@bp2": { flexDirection: "row" },
            }}
            key={`${jv.vertical}-${index}`}
          >
            <Box
              css={{
                pl: "$5",
                borderLeft: "4px solid $grey3",
                mb: "$6",
                pb: "-5px",
                pr: "$6",
                "@bp2": {
                  p: "0px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "$6",
                  border: "none",
                  mb: "0px",
                },
              }}
            >
              <Field
                labelCss={{ marginBottom: "0px" }}
                name={`jobVerticals.${index}.talentRequired`}
              >
                <FormikInput
                  variant="rebrand"
                  name={`jobVerticals.${index}.talentRequired`}
                  value={talentRequiredValue || ""}
                  type="number"
                  placeholder="e.g. 2"
                  inputSuffix={
                    <Text variant="mini" color="black">
                      {verticalMap[jv.vertical]}
                      {pluralize && "S"}
                    </Text>
                  }
                  css={{
                    "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "-moz-appearance": "none",
                    borderColor: "$grey3",
                    border: "solid 1px",
                    minWidth: "269px",
                  }}
                />
              </Field>
              {hasDuration && (
                <Field name={`jobVerticals.${index}.jobLength`}>
                  <FormikInput
                    variant="rebrand"
                    name={`jobVerticals.${index}.jobLength`}
                    value={jobLengthValue || ""}
                    type="number"
                    placeholder="e.g. 1"
                    inputSuffix={
                      <Text variant="mini" color="black">
                        Hours
                      </Text>
                    }
                    css={{
                      "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                          margin: 0,
                        },
                      "-moz-appearance": "none",
                      borderColor: "$grey3",
                      border: "solid 1px",
                      minWidth: "269px",
                    }}
                  />
                </Field>
              )}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  </Box>
);

interface TimeslotsFieldProps {
  masterTimeslotsDisplay: InputMaybe<TimeslotInput[]>;
  customTimeslotsList: number[] | undefined;
  setFieldValue: (field: string, value: Date) => void;
  timesConfirmed: boolean;
  setTimesConfirmed: (value: React.SetStateAction<boolean>) => void;

  jobVerticals:
    | InputMaybe<CreateJobVerticalInput[]>
    | InputMaybe<PackageVertical[]>;
  toggleCustomTimesModal: () => void;
}

export const TimeslotsField = ({
  masterTimeslotsDisplay,
  customTimeslotsList,
  setFieldValue,
  timesConfirmed,
  jobVerticals,
  toggleCustomTimesModal,
  setTimesConfirmed,
}: TimeslotsFieldProps) => {
  const [timezone, setTimezone] = useState<string>("");

  const formatTimezone = (timezoneId: string): string => {
    try {
      const now = new Date();
      const offsetInMinutes = now.getTimezoneOffset();

      return `${timezoneId} (GMT${offsetInMinutes >= 0 ? "+" : ""}${
        offsetInMinutes / -60
      })`;
    } catch (error) {
      console.error(error);
      return `${timezoneId} (Unable to retrieve offset)`;
    }
  };

  useEffect(() => {
    const getCurrentTimezone = () => {
      const options = Intl.DateTimeFormat().resolvedOptions();
      const humanReadableTimezone = formatTimezone(
        options.timeZone || "Unknown",
      );
      setTimezone(humanReadableTimezone);
    };

    getCurrentTimezone();
  }, []);

  return (
    <Flex css={{ flexDirection: "column", mr: "$9" }}>
      <Flex css={{ gap: "$3", alignItems: "center" }}>
        <Text css={{ my: "$4" }}>Job Times</Text>{" "}
        <Flex
          css={{
            alignItems: "Center",
            gap: "$3",
          }}
        >
          <FormikCheckbox
            css={{ height: "18px", width: "18px" }}
            name="timesUnconfirmed"
            variant="rebrand"
            onClick={() => setTimesConfirmed(!timesConfirmed)}
          />
          <Text variant="body">Times are not yet confirmed</Text>
        </Flex>
      </Flex>
      {timesConfirmed && (
        <>
          <Flex
            css={{
              flexDirection: "column",
              "@bp1": {
                flexDirection: "row",
                alignItems: "flex-start",
              },
            }}
          >
            <Flex css={{ flexDirection: "column" }}>
              {masterTimeslotsDisplay?.map((timeslot, tsIndex) => {
                const timeslotCustomised =
                  customTimeslotsList?.includes(tsIndex);
                return (
                  <Flex
                    css={{
                      flexDirection: "column",
                      pl: "$5",
                      borderLeft: "4px solid $grey3",
                      "@bp2": {
                        flexDirection: "row",
                        alignItems: "center",
                        pl: "0px",
                        border: "none",
                      },
                    }}
                    key={`timeslot-${tsIndex}`}
                  >
                    <CustomJobTimeField
                      setFieldValue={setFieldValue}
                      name={`jobVerticals.0.timeslots.${tsIndex}`}
                      key={`jobVerticals.0.timeslots.${tsIndex}`}
                      timeslot={timeslot}
                      customised={timeslotCustomised}
                      disabled={!timesConfirmed}
                      jobVerticals={jobVerticals}
                      context="standard"
                      tsIndex={tsIndex}
                      showDate={false}
                    />
                    <Flex
                      css={{
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "$4",
                        borderRadius: "20px",
                        height: "42px",
                        width: timeslotCustomised ? "244px" : "262px",
                        backgroundColor: timeslotCustomised
                          ? "black"
                          : "$grey1",
                        mb: "$3",
                        "@bp2": {
                          ml: "$6",
                          mb: "$6",
                          backgroundColor: timeslotCustomised
                            ? "black"
                            : "white",
                        },
                      }}
                      data-test-id="CustomTimeslotsModal"
                      onClick={toggleCustomTimesModal}
                    >
                      <Icon
                        variant="responseTime"
                        size={18}
                        color={timeslotCustomised ? "white" : "black"}
                      />
                      <Text
                        css={{
                          cursor: "pointer",
                          fontSize: "14px",
                          lineHeight: "14px",
                          textTransform: "uppercase",
                        }}
                        weight="bold"
                        color={timeslotCustomised ? "white" : "black"}
                      >
                        {timeslotCustomised ? "Edit" : "Define"} custom job
                        times
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          {timezone && (
            <Flex css={{ gap: "$3", alignItems: "center" }}>
              <Icon variant="globeRound" />
              <Text>{timezone}</Text>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

type JobDetailsFormComponentsProps = LocationFieldProps &
  DatePickerProps &
  PaymentCalculatorProps &
  DescriptionFieldProps &
  UsageFieldProps &
  DeckFileUploadProps &
  LegacyFeeEstimateFieldProps &
  DeliverablesFieldProps &
  JobLengthFieldProps &
  TypeFieldProps;

export function renderJobFormField(
  field: Component,
  props: JobDetailsFormComponentsProps,
  key: number,
) {
  const componentProps = Object.assign({}, props, field.props);
  switch (field.name) {
    case "VirtualEventToggle":
      return <VirtualEventToggle {...componentProps} key={key} />;
    case "TypeField":
      return <TypeField {...componentProps} key={key} />;
    case "NameField":
      return <NameField {...componentProps} key={key} />;
    case "ClientField":
      return <ClientField {...componentProps} key={key} />;
    case "LocationField":
      return <LocationField {...componentProps} key={key} />;
    case "PaymentCalculator":
      return <PaymentCalculator {...componentProps} key={key} />;
    case "DescriptionField":
      return <DescriptionField {...componentProps} key={key} />;
    case "UsageField":
      return <UsageField {...componentProps} key={key} />;
    case "DeckFileUpload":
      return <DeckFileUpload {...componentProps} key={key} />;
    case "FeeEstimateField":
      return <LegacyFeeEstimateField {...componentProps} key={key} />;
    case "Deliverables":
      return <DeliverablesField {...componentProps} key={key} />;
    case "JobLengthField":
      return <JobLengthField {...componentProps} key={key} />;
    case "FullLocationField":
      return <FullLocationField {...componentProps} key={key} />;
    default:
      return <Box key={key}></Box>;
  }
}

export type JobVerticalFormComponentsProps = LegacyFeeEstimateFieldProps &
  DeliverablesFieldProps &
  JobLengthFieldProps &
  TalentRequiredFieldProps;

export const SchemaVerticalDetailsField = ({
  field,
  props,
  formValuesIndex,
}: {
  field: Component;
  props: JobVerticalFormComponentsProps;
  formValuesIndex: number;
}) => {
  const componentProps = Object.assign(props, field.props);
  switch (field.name) {
    case "FeeEstimateField":
      return (
        <LegacyFeeEstimateField
          {...componentProps}
          name={`jobVerticals.${formValuesIndex}.budget`}
        />
      );
    case "Deliverables":
      return (
        <DeliverablesField
          {...componentProps}
          name={`jobVerticals.${formValuesIndex}.deliverables`}
        />
      );
    case "JobLengthField":
      return (
        <JobLengthField
          {...componentProps}
          name={`jobVerticals.${formValuesIndex}.jobLength`}
        />
      );
    case "TalentRequiredField":
      return (
        <TalentRequiredField
          {...componentProps}
          name={`jobVerticals.${formValuesIndex}.talentRequired`}
        />
      );
    default:
      return <></>;
  }
};
