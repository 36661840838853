import React, { useCallback, useState, useEffect } from "react";
import * as Stitches from "@stitches/react";
import { Pill } from "./Pill";
import { mergeCss, styled } from "src/ccl/stitches";
import { Icon, Text, TextVariants } from "src/ccl/document";
import { AvailableIcon } from "src/ccl/document/icon";
import { Box, Flex } from "src/ccl/layout";
import { Dropdown } from "src/ccl/navigation";
import { iconSizes, tokens } from "src/ccl/stitches/theme";

const Caret = styled(Box, {
  width: "0",
  height: "0",
  position: "absolute",
  top: -6,
  right: "50%",
  borderLeftWidth: "6px",
  borderLeftColor: "transparent",
  borderLeftStyle: "solid",
  borderRightWidth: "6px",
  borderRightColor: "transparent",
  borderRightStyle: "solid",
  borderBottomStyle: "solid",
  borderBottomWidth: "6px",
});

export interface DropdownPillProps {
  icon: AvailableIcon;
  iconSize?: keyof typeof iconSizes;
  text: string;
  textVariant?: keyof typeof TextVariants;
  active: boolean;
  children: React.ReactNode;
  withHoverEffect?: boolean;
  selectedFilter?: string | null;
  setSelectedFilter?: (filter: string | null) => void;
  noGutter?: boolean;
  caretColor?: keyof typeof tokens.colors;
  caretPosition?: "left" | "right" | "center";
  withCloseDelay?: boolean;
  css?: Stitches.CSS;
  onCloseCallback?: () => void;
  initiallyOpen?: boolean;
}

export const DropdownPill = ({
  icon,
  iconSize = 24,
  text,
  textVariant = "body",
  active,
  children,
  withHoverEffect = false,
  selectedFilter,
  setSelectedFilter,
  noGutter = false,
  caretColor = "white",
  caretPosition = "center",
  withCloseDelay = false,
  css = {},
  initiallyOpen,
  onCloseCallback,
}: DropdownPillProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClose = useCallback(() => setDropdownOpen(false), []);
  const onOpen = useCallback(() => setDropdownOpen(true), []);

  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    if (!dropdownOpen && setSelectedFilter) {
      setHovered(true);
      setSelectedFilter(text);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDelay = () => {
    if (withCloseDelay && initiallyOpen) {
      setDropdownOpen(true);
      const timer = setTimeout(() => {
        setDropdownOpen(false);
        onCloseCallback && onCloseCallback();
      }, 2000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    if (selectedFilter !== undefined && selectedFilter !== text) {
      onClose();
    }
  }, [selectedFilter]);

  useEffect(() => handleDelay(), [initiallyOpen]);

  return (
    <Box
      data-test-id={`DropdownPill-${text}`}
      css={{ width: "fit-content", zIndex: 400 }}
      onMouseOver={() => {
        withHoverEffect && handleMouseOver();
      }}
      onMouseLeave={() => {
        withHoverEffect && handleMouseLeave();
      }}
    >
      <Dropdown
        position="center"
        bodyCss={mergeCss(
          {
            border: "none",
            boxShadow: "$dropBlack",
            width: "max-content",
            borderRadius: "8px",
            zIndex: 351,
            maxHeight: 350,
            overflowY: "auto",
          },
          noGutter ? { padding: "$0" } : {},
        )}
        wrapperCss={{ zIndex: 350 }}
        onClose={() => {
          onClose();
        }}
        onOpen={() => {
          onOpen();
          setSelectedFilter && setSelectedFilter(text);
        }}
        withHoverEffect
        dropdownOpen={dropdownOpen}
        launcher={
          <Pill
            variant="rebrand"
            css={mergeCss(
              {
                borderColor:
                  dropdownOpen || hovered || active ? "$black" : "$grey3",
                backgroundColor: active ? "$black" : "inherit",
                width: "fit-content",
              },
              css,
            )}
          >
            <Flex
              css={{
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "space-between",
                width: "inherit",
              }}
            >
              <Flex css={{ alignItems: "center" }}>
                <Icon
                  variant={icon}
                  size={iconSize}
                  css={{
                    mr: "$3",
                  }}
                  color={
                    active
                      ? "white"
                      : dropdownOpen || hovered
                      ? "black"
                      : "grey6"
                  }
                />
                <Text
                  variant={textVariant}
                  color={
                    active
                      ? "white"
                      : dropdownOpen || hovered
                      ? "black"
                      : "grey6"
                  }
                >
                  {text}
                </Text>
              </Flex>
              <Icon
                variant="chevronDown"
                size={14}
                color={
                  active ? "white" : dropdownOpen || hovered ? "black" : "grey4"
                }
                css={{ ml: "$4" }}
              />
            </Flex>
          </Pill>
        }
      >
        <Caret
          data-test-id="caret"
          css={{
            borderBottomColor: caretColor,
            right:
              caretPosition === "right"
                ? "25%"
                : caretPosition === "left"
                ? "75%"
                : "50%",
          }}
        />
        {children}
      </Dropdown>
    </Box>
  );
};
