import React, { useEffect, useState } from "react";
import { Reference, useMutation } from "@apollo/client";
import { format, formatDistanceToNow } from "date-fns";
import { Box, Flex } from "src/ccl/layout";
import { ConfirmModal, Note, Text } from "src/ccl/document";
import { Maybe, Note as NoteType } from "src/graphql/types";
import { getInitials, pluralize } from "src/utils/lang";
import { Button } from "src/ccl/navigation";
import { Avatar } from "src/ccl/blocks";
import { Textarea } from "src/ccl/data-entry";
import { sortByCreatedAt } from "src/utils/array";
import { ParagraphRenderer } from "src/components/ParagraphRenderer";
import { NOTE_DETAILS_FRAGMENT } from "src/graphql/fragments";
import {
  CREATE_JOB_NOTE_MUTATION,
  DELETE_JOB_NOTE_MUTATION,
} from "src/graphql/mutations";

const initialNotesToShow = 2;

interface JobActivityStreamNotesProps {
  jobId: string;
  notes: Maybe<NoteType[]>;
}

export const JobActivityStreamNotes = ({
  notes,
  jobId,
}: JobActivityStreamNotesProps) => {
  const [noteContent, setNoteContent] = useState("");
  const [isDeleteConfirmModalShowing, setIsDeleteConfirmModalShowing] =
    useState(false);
  const [noteIdToDelete, setNoteIdToDelete] = useState<undefined | string>(
    undefined,
  );
  const [noteInputFieldDisabled, setNoteInputFieldDisabled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const sortedNotes = sortByCreatedAt(notes || []);

  const [createNote] = useMutation(CREATE_JOB_NOTE_MUTATION, {
    update(cache, { data: { createJobNote } }) {
      cache.modify({
        id: `Job:${jobId}`,
        fields: {
          notes(existingNoteRefs = []) {
            const newNoteRef = cache.writeFragment({
              data: createJobNote,
              fragment: NOTE_DETAILS_FRAGMENT,
            });

            return [...existingNoteRefs, newNoteRef];
          },
        },
      });
    },
  });

  const [deleteNote, { loading: deleteNoteLoading }] = useMutation(
    DELETE_JOB_NOTE_MUTATION,
    {
      update(cache, { data: { deleteJobNote } }) {
        cache.modify({
          id: `Job:${jobId}`,
          fields: {
            notes(existingNoteRefs: Reference[] = [], { readField }) {
              return existingNoteRefs.filter(
                (noteRef) => deleteJobNote.id !== readField("id", noteRef),
              );
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    setIsCollapsed(!!notes && notes.length > initialNotesToShow);
  }, [notes]);

  return (
    <Box css={{ py: "$6" }}>
      {sortedNotes.length > 0 && (
        <Text variant="h3" css={{ mb: "$9" }}>
          {pluralize(sortedNotes.length, "Note")}
        </Text>
      )}

      <Flex css={{ justifyContent: "space-between" }}>
        <Text css={{ fontSize: "$16", lineHeight: "$26", fontWeight: "$bold" }}>
          Leave a note
        </Text>

        <Text css={{ fontSize: "$14", lineHeight: "$26" }}>
          Press enter to send
        </Text>
      </Flex>

      <Textarea
        value={noteContent}
        placeholder="Press shift+enter to add a new line"
        onKeyUp={async (e: React.KeyboardEvent) => {
          if (e.keyCode === 13 && !e.shiftKey) {
            setNoteInputFieldDisabled(true);

            await createNote({
              variables: {
                jobId: jobId,
                body: noteContent.replace(/^\s+|\s+$/g, ""),
              },
            });

            setNoteInputFieldDisabled(false);
            setNoteContent("");
          }
        }}
        onChange={(e) => {
          setNoteContent(e.target.value);
        }}
        disabled={noteInputFieldDisabled}
      />

      {sortedNotes.length > 0 && (
        <>
          <Box
            css={{
              mt: "$7",
              position: "relative",
            }}
          >
            <Box
              css={{
                position: "absolute",
                bottom: 0,
                height: 50,
                width: "100%",
                linearGradient: isCollapsed
                  ? "180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 67.76%"
                  : "initial",
                zIndex: "$100",
              }}
            />
            {sortedNotes
              .slice(0, isCollapsed ? initialNotesToShow : sortedNotes.length)
              .map((note) => (
                <Note
                  key={note.id}
                  showDeleteButton={true}
                  onDelete={() => {
                    setNoteIdToDelete(note.id);
                    setIsDeleteConfirmModalShowing(true);
                  }}
                  deleteButtonDisabled={deleteNoteLoading}
                  heading={
                    <Flex css={{ mb: "$3" }}>
                      <Avatar
                        imageUrl={note.user.featuredAsset?.mediaUrl}
                        initials={getInitials(note.user.name)}
                      />

                      <Box css={{ ml: "$3" }}>
                        <Text variant="mini">{note.user.name}</Text>
                        <Text
                          variant="meta"
                          color="grey6"
                          title={format(
                            new Date(note.createdAt),
                            "dd/MM/yyyy HH:mm:ss",
                          )}
                        >
                          {formatDistanceToNow(new Date(note.createdAt))} ago
                        </Text>
                      </Box>
                    </Flex>
                  }
                >
                  <ParagraphRenderer
                    text={note.body}
                    css={{ wordBreak: "break-word" }}
                  />
                </Note>
              ))}
          </Box>

          {sortedNotes.length > initialNotesToShow && (
            <Flex css={{ justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
                css={{ width: "100%", "@bp2": { width: "unset" } }}
              >
                {!isCollapsed ? "Hide notes" : "Show all notes"}
              </Button>
            </Flex>
          )}

          <ConfirmModal
            title="Please confirm"
            isOpen={isDeleteConfirmModalShowing}
            confirmButtonTitle="Delete"
            confirmButtonLoadingTitle="Deleting..."
            cancelButtonTitle="Cancel"
            onCancel={() => {
              setIsDeleteConfirmModalShowing(false);
            }}
            onConfirm={async () => {
              if (noteIdToDelete === undefined) {
                return;
              }

              await deleteNote({
                variables: {
                  jobId: jobId,
                  noteId: noteIdToDelete,
                },
              });
              setNoteIdToDelete(undefined);
              setIsDeleteConfirmModalShowing(false);
            }}
            isDisabled={deleteNoteLoading}
          >
            <Text>Are you sure you want to delete this note?</Text>
          </ConfirmModal>
        </>
      )}
    </Box>
  );
};
