import { Avatar } from "src/ccl/blocks/avatar";
import { Text } from "src/ccl/document";
import { Dropdown, ExternalLink, Link } from "src/ccl/navigation";
import { styled } from "src/ccl/stitches";
import { UserKindContext, useFeatures } from "src/hooks";
import { useNewDashboard } from "src/hooks/useNewDashboard";

const ProfileDropdownBody = styled("div", {
  p: "$4",
});

const ProfileDropdownLauncher = styled("div", {
  display: "none",

  "@bp2": {
    display: "block",
  },
});

const profileDropdownLinkStyles = {
  display: "block",
  minWidth: "300px",
  textDecoration: "none",
  lineHeight: "$26",
  my: "$4",

  "&:last-child": {
    mb: "$0",
  },
};

const ProfileDropdownLink = styled(Link, profileDropdownLinkStyles);
const ProfileDropdownSignOut = styled(ExternalLink, profileDropdownLinkStyles);

interface ProfileDropdownProps {
  avatarImageUrl?: string;
  avatarInitials?: string;
  onSignOut?: () => void;
  userName?: string;
  userEmail?: string;
  context: UserKindContext;
  isAgent?: boolean;
}

export const ProfileDropdown = ({
  avatarImageUrl,
  avatarInitials,
  onSignOut,
  userName,
  userEmail,
  context,
  isAgent,
}: ProfileDropdownProps) => {
  const { featureEnabled } = useFeatures();
  const { isNewDashboard } = useNewDashboard();

  const navigateTo = (option: string) => {
    let userRole = "agent";

    if (!isAgent) {
      userRole = context;
    }
    const linkMapping: Record<string, string> = {
      dashboard: `/dashboard/${userRole}/jobs`,
      settings: `/dashboard/${userRole}/account/settings`,
      password: `/dashboard/${userRole}/account/password`,
    };

    return linkMapping[option];
  };
  return (
    <Dropdown
      position="right"
      launcher={
        <ProfileDropdownLauncher
          css={
            featureEnabled("unified_dashboards")
              ? {
                  display: "none",
                  "@md": {
                    display: "initial",
                  },
                }
              : {}
          }
        >
          <div data-test-id="HeaderAvatar">
            <Avatar
              css={{
                backgroundColor: "$grey2",
                border: avatarImageUrl ? "none" : "2px solid $black",
              }}
              fontColor="black"
              initials={avatarInitials}
              imageUrl={avatarImageUrl}
            />
          </div>
        </ProfileDropdownLauncher>
      }
      wrapperCss={
        featureEnabled("unified_dashboards")
          ? {
              display: "none",
              "@md": {
                display: "initial",
              },
            }
          : {}
      }
    >
      <ProfileDropdownBody>
        <Text
          variant="meta"
          css={{
            fontWeight: "$bold",
            minWidth: "300px",
            color: "$grey6",
            mb: "$3",
          }}
        >
          {userName}
        </Text>

        <Text
          variant="meta"
          css={{
            minWidth: "300px",
            color: "$grey6",
            mb: "$3",
            borderBottom: "1px $grey2 solid",
            paddingBottom: "$4",
          }}
        >
          {userEmail}
        </Text>

        {context === "client" && (
          <ProfileDropdownLink
            to={isNewDashboard ? navigateTo("dashboard") : "/dashboard/client"}
          >
            Dashboard
          </ProfileDropdownLink>
        )}

        <ProfileDropdownLink
          to={isNewDashboard ? navigateTo("settings") : "/account/settings"}
        >
          Settings
        </ProfileDropdownLink>

        <ProfileDropdownLink
          to={isNewDashboard ? navigateTo("password") : "/account/password"}
        >
          Change password
        </ProfileDropdownLink>

        <ProfileDropdownSignOut
          to="/"
          eventName="header:signOut"
          onClick={(e) => {
            e.preventDefault();

            if (onSignOut) {
              onSignOut();
            }
          }}
        >
          Sign out
        </ProfileDropdownSignOut>
      </ProfileDropdownBody>
    </Dropdown>
  );
};
