import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useQuery } from "@apollo/client";
import { styled } from "@stitches/react";
import { BudgetFilter } from "./BudgetFilter";
import { AdvancedFiltersModal } from "./AdvancedFiltersModal";
import { AdvancedFiltersDropdown } from "./AdvancedFiltersDropdown";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import {
  CreativeSearchRefineOption,
  Filter,
  FilterContext,
  Gender,
  OrderingOption,
  Query,
  Scalars,
  SortDirection,
  TalentVertical,
  VerticalFieldInput,
} from "src/graphql/types";
import { SortCreativesFilter } from "src/components/dashboards/booker/shortlist";
import {
  BookmarkedFilter,
  CreativeFiltersModal,
  GenderFilter,
  HideContactTalentFilter,
  LocationFilter,
  SortCreativesModal,
} from "src/components/createJob";
import { DAY_RATE_DISTRIBUTION_QUERY } from "src/graphql/queries/talent/DayRateDistribution";
import { useToggle } from "src/hooks";
import { SearchFilter, VerticalFilter } from "src/components/filtering";
import { MobileFilterButton } from "src/components/filtering/MobileFilterButton";
import { SelectReact } from "src/ccl/data-entry";
import { GroupFilter } from "src/components/createJob/creativeFilters/GroupFilter";
import { ManagedTalentSortModal } from "src/components/createJob/creativeFilters/ManagedTalentSortModal";

export const VALUE_DELIMITER = "|";

interface Option {
  value: string;
  label: string;
}

const ToggleButton = styled("div", {
  display: "flex",
  height: "inherit",
  width: "32px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "2.3px",
  cursor: "pointer",
});

interface CreativeFiltersProps {
  context: FilterContext;
  verticals: TalentVertical[];
  setVerticals: (verticals: TalentVertical[]) => void;
  name?: string;
  locations: string[];
  setLocations: (locations: string[]) => void;
  genders: Gender[];
  setGenders: (gender: Gender[]) => void;
  setName: (name: string) => void;
  loading: boolean;
  onReset: () => void;
  refineValue: CreativeSearchRefineOption;
  setRefineValue: (value: CreativeSearchRefineOption) => void;
  minDayRate: number;
  maxDayRate: number;
  setDayRates: (values: [number, number]) => void;
  totalCount?: number;
  fields: VerticalFieldInput[];
  advancedFilterValues: Record<string, Scalars["VerticalFieldValueScalar"]>;
  bookmarked?: boolean;
  setBookmarked?: (v: boolean) => void;
  showListView?: boolean;
  setShowListView?: (state: boolean) => void;
  excludeAgencyIds?: string[];
  setExcludeAgencyIds?: (v: string[]) => void;
  defaultOrderingOption?: OrderingOption;
  orderingOptions?: OrderingOption[];
  onSortChange?: (sortField: string, sortDirection: SortDirection) => void;
  group?: string;
  setGroup?: React.Dispatch<React.SetStateAction<string>>;
  groupFilter?: Filter;
  showTotalCount?: boolean;
  allowAdvanceFilter?: boolean;
}
export const CreativeFilters = ({
  context,
  verticals,
  setVerticals,
  name,
  setName,
  locations,
  setLocations,
  genders,
  setGenders,
  loading,
  onReset,
  refineValue,
  setRefineValue,
  minDayRate,
  maxDayRate,
  setDayRates,
  totalCount,
  fields,
  advancedFilterValues,
  bookmarked,
  setBookmarked,
  showListView,
  setShowListView,
  excludeAgencyIds,
  setExcludeAgencyIds,
  defaultOrderingOption,
  orderingOptions,
  onSortChange,
  group,
  setGroup,
  groupFilter,
  showTotalCount = true,
  allowAdvanceFilter = true,
}: CreativeFiltersProps) => {
  const [sortModalOpen, toggleSortModal] = useToggle();
  const [filterModalOpen, toggleFilterModal] = useToggle();

  const {
    data,
    refetch,
    loading: dayRateDistributionLoading,
  } = useQuery<Query>(DAY_RATE_DISTRIBUTION_QUERY, {
    variables: {
      verticals: verticals,
    },
  });
  const { dayRateDistribution, maximumMinDayRate: defaultMaximum } = data || {
    dayRateDistribution: [] as number[],
    maximumMinDayRate: 3000,
  };

  useDeepCompareEffect(() => {
    if (!loading && !dayRateDistributionLoading) {
      refetch();
    }
  }, [verticals]);

  const [advancedFiltersModalOpen, toggleAdvancedFiltersModalOpen] =
    useToggle();
  const filtersActive = !!(
    (name && name.length > 0) ||
    verticals.length > 0 ||
    locations.length ||
    maxDayRate ||
    minDayRate ||
    fields.length > 0 ||
    genders.length > 0 ||
    Object.values(advancedFilterValues).length > 0 ||
    !!bookmarked ||
    (!!excludeAgencyIds && excludeAgencyIds.length)
  );

  const handleAdvancedFilterSelection = (vertical?: TalentVertical) => {
    if (vertical) {
      setVerticals([vertical]);
    }

    toggleAdvancedFiltersModalOpen();
  };

  const [selectedFilter, setSelectedFilter] = useState<string | null>();

  return (
    <Flex
      css={{
        justifyContent:
          context === FilterContext.TalentManagement ? "start" : "center",
      }}
    >
      {context === FilterContext.CreateAJob && (
        <SortCreativesModal
          refineValue={refineValue}
          setRefineValue={setRefineValue}
          isOpen={sortModalOpen}
          onClose={toggleSortModal}
          creativeCount={totalCount}
        />
      )}
      {context === FilterContext.TalentManagement &&
        orderingOptions &&
        onSortChange && (
          <ManagedTalentSortModal
            isOpen={sortModalOpen}
            onClose={toggleSortModal}
            defaultOrderingOption={defaultOrderingOption}
            options={orderingOptions.map((option) => ({
              label: option.label,
              value: `${option.field}${VALUE_DELIMITER}${option.direction}`,
            }))}
            onChange={(option) => {
              const [field, direction] = (option as Option).value.split(
                VALUE_DELIMITER,
              );
              onSortChange(
                field,
                direction === "asc" ? SortDirection.Asc : SortDirection.Desc,
              );
            }}
          />
        )}
      <CreativeFiltersModal
        filterContext={context}
        verticals={verticals}
        setVerticals={setVerticals}
        nameOrBrand={name}
        setNameOrBrand={setName}
        locations={locations}
        setLocations={setLocations}
        genders={genders}
        setGenders={setGenders}
        isOpen={filterModalOpen}
        onClose={toggleFilterModal}
        minDayRate={minDayRate}
        maxDayRate={maxDayRate}
        setDayRates={setDayRates}
        dayRateDistribution={dayRateDistribution}
        defaultMaximum={defaultMaximum}
        creativeCount={totalCount}
        refineValue={refineValue}
        setRefineValue={setRefineValue}
        onReset={onReset}
        allowAdvanceFilter={allowAdvanceFilter}
      />
      <AdvancedFiltersModal
        filterContext={context}
        loading={loading}
        totalCount={totalCount}
        onClose={toggleAdvancedFiltersModalOpen}
        vertical={verticals[0]}
        isOpen={advancedFiltersModalOpen}
      />
      <Grid
        css={{
          "@bp4": { display: "none" },
          gridColumns:
            context === FilterContext.CreateAJob ||
            context === FilterContext.TalentManagement
              ? "1fr 1fr"
              : 1,
          borderBottom: "1px solid $grey2",
          pb: "$4",
          width: "100%",
        }}
      >
        {(context === FilterContext.CreateAJob ||
          context === FilterContext.TalentManagement) && (
          <MobileFilterButton
            text={context === FilterContext.CreateAJob ? "Refine" : "Sort"}
            icon="sortRows"
            borderRight={true}
            onClick={toggleSortModal}
          />
        )}
        <MobileFilterButton
          text="Filter"
          icon="filter"
          onClick={toggleFilterModal}
          borderRight={false}
        />
      </Grid>
      <Box
        css={{
          display: "none",
          "@bp4": { display: "block", width: "100%" },
        }}
      >
        <Flex
          css={{
            justifyContent:
              context === FilterContext.TalentManagement ? "start" : "center",
            flexWrap: "wrap",
            gap: "14px",
          }}
        >
          <SearchFilter
            placeholder="Search by name"
            search={name}
            onChange={setName}
          />
          <VerticalFilter
            verticals={verticals}
            onChange={setVerticals}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          <LocationFilter
            locations={locations}
            onChange={setLocations}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          <GenderFilter
            genders={genders}
            onChange={setGenders}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          {context === FilterContext.TalentManagement && groupFilter && (
            <GroupFilter
              group={group}
              setGroup={setGroup}
              groups={groupFilter.options || []}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          )}
          <BudgetFilter
            minDayRate={minDayRate}
            maxDayRate={maxDayRate}
            setDayRates={setDayRates}
            dayRateDistribution={dayRateDistribution}
            defaultMaximum={defaultMaximum}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          {bookmarked !== undefined && setBookmarked && (
            <BookmarkedFilter
              bookmarked={bookmarked}
              setBookmarked={setBookmarked}
            />
          )}
          {setExcludeAgencyIds && (
            <HideContactTalentFilter
              hideContactTalent={!!excludeAgencyIds?.length}
              setHideContactTalent={(hideContactTalent) =>
                hideContactTalent
                  ? setExcludeAgencyIds(["1"])
                  : setExcludeAgencyIds([])
              }
            />
          )}
          {context !== FilterContext.TalentManagement && (
            <AdvancedFiltersDropdown
              verticals={verticals}
              onClick={handleAdvancedFilterSelection}
              fields={fields}
              advancedFilters={advancedFilterValues}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          )}
        </Flex>

        <Flex
          css={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            mt: "$10",
            mb: "$11",
          }}
        >
          {setShowListView && (
            <Flex
              css={{
                width: "78px",
                height: "32px",
                position: "absolute",
                left: "0",
                gap: "13px",
              }}
            >
              <ToggleButton
                css={{
                  backgroundColor: showListView ? "$grey1" : "$black",
                }}
                onClick={() => {
                  setShowListView(false);
                }}
                data-test-id="GridViewToggle"
              >
                <Icon size={18} variant="gridView" />
              </ToggleButton>
              <ToggleButton
                css={{
                  backgroundColor: showListView ? "$black" : "$grey1",
                }}
                onClick={() => {
                  setShowListView(true);
                }}
                data-test-id="ListViewToggle"
              >
                <Icon size={18} variant="listView" />
              </ToggleButton>
            </Flex>
          )}

          <Box />
          <Flex css={{ justifyContent: "center" }}>
            {!loading && showTotalCount && (
              <>
                <Text
                  color="grey4"
                  css={{
                    pr: filtersActive ? "$3" : undefined,
                  }}
                >{`${totalCount || "No"} ${
                  totalCount === 1 ? "talent" : "talents"
                } found`}</Text>
                {filtersActive && (
                  <Text
                    css={{
                      textDecoration: "underline",
                      "&:hover": { cursor: "pointer" },
                    }}
                    onClick={onReset}
                  >
                    Clear Filters
                  </Text>
                )}
              </>
            )}
          </Flex>
          <Box
            css={{
              position: "absolute",
              right: "0",
              zIndex: "$300",
              "@bp3": {
                mr: "$5",
              },
              "@bp5": {
                mr: "$2",
              },
            }}
          >
            {(context === FilterContext.CreateAJob ||
              context === FilterContext.PackageShortlist) && (
              <SortCreativesFilter
                refineValue={refineValue}
                setRefineValue={setRefineValue}
              />
            )}
            {context === FilterContext.TalentManagement &&
              defaultOrderingOption &&
              orderingOptions &&
              onSortChange && (
                <SelectReact
                  variant="rebrand"
                  initialValues={{
                    label: defaultOrderingOption.label,
                    value: `${defaultOrderingOption.field}${VALUE_DELIMITER}${defaultOrderingOption.direction}`,
                  }}
                  options={orderingOptions.map((option) => ({
                    label: option.label,
                    value: `${option.field}${VALUE_DELIMITER}${option.direction}`,
                  }))}
                  onChange={(option) => {
                    const [field, direction] = (option as Option).value.split(
                      VALUE_DELIMITER,
                    );
                    onSortChange(
                      field,
                      direction === "asc"
                        ? SortDirection.Asc
                        : SortDirection.Desc,
                    );
                  }}
                  valueContent={<Icon variant="sortRows" size={20} />}
                  dropdownCss={{
                    display: "none",
                  }}
                  backgroundColor="#f3f3f3"
                  showBorder={false}
                  hasMargin={false}
                />
              )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
