import { formatDate } from "./dates";
import { AvailableIcon } from "src/ccl/document";
import { jobStates, tokens } from "src/ccl/stitches/theme";
import {
  ComponentOption,
  EdgeList,
  InvoiceState,
  JobState,
  JobTalentState,
} from "src/graphql/types";

export const notNil = (v: unknown) => typeof v !== "undefined" && v !== null;

export const nil = (v: unknown) => !notNil(v);

export const pluralize = (
  count: number,
  singular: string,
  plural = `${singular}s`,
) => (count === 1 ? `${count} ${singular}` : `${count} ${plural}`);

export const uppercaseFirstLetter = (str: string) =>
  `${str.slice(0, 1).toUpperCase()}${str.slice(1, str.length)}`;

export const uppercaseFirstLetterOfEachWord = (str: string) =>
  str
    .split(" ")
    .map((s) => uppercaseFirstLetter(s))
    .join(" ");

export const toSentence = (
  items: string[],
  conjunction = "and",
  capitalizeFirstLetter = false,
) => {
  if (items.length === 0) {
    return "";
  }

  if (items.length === 1) {
    return capitalizeFirstLetter ? uppercaseFirstLetter(items[0]) : items[0];
  }

  const sentence = `${items
    .slice(0, items.length - 1)
    .join(", ")} ${conjunction} ${items[items.length - 1]}`;

  if (capitalizeFirstLetter) {
    return uppercaseFirstLetter(sentence);
  } else {
    return sentence;
  }
};

export const toList = (items: string[]) => {
  if (items.length === 0) {
    return "";
  }

  if (items.length === 1) {
    return items[0].replace(/_/g, " ");
  }

  return items.slice(0, items.length).join(", ").replace(/_/g, " ");
};

export const truncate = (text: string, length = 100, omission = "...") => {
  if (text.length <= length) {
    return text;
  }
  return `${text.slice(0, length)}${omission}`;
};

export const truncateWords = (
  text: string,
  numberOfWords = 10,
  omission = "...",
) => {
  const words = text.split(" ");

  if (words.length <= numberOfWords) {
    return text;
  }

  return `${words.slice(0, numberOfWords).join(" ")}${omission}`;
};

export const niceStateText = (state: string) => {
  if (state === "finalized" || state === "FINALIZED") {
    return "Confirmed";
  }

  return uppercaseFirstLetter(state.split("_").join(" ").toLowerCase());
};

export const getInitials = (name: string | undefined) => {
  if (!name) {
    return "";
  }

  return name
    .split(" ")
    .filter((s) => s !== "")
    .map((s) => s[0].toUpperCase())
    .join("");
};

export const extractNodes = <T>(graphqlResult: EdgeList | undefined) => {
  if (graphqlResult === undefined || graphqlResult?.edges === undefined) {
    return [];
  }

  return graphqlResult.edges.map((edge) => edge?.node).filter(notNil) as T[];
};

export const sentenceCase = (s: string) =>
  uppercaseFirstLetter(s.toLowerCase());

export const sentenceCaseOptions = (options: ComponentOption[]) => {
  options.forEach((o) => (o.label = sentenceCase(o.label)));

  return options;
};

export const invoiceStateText = (state: InvoiceState) => {
  switch (state) {
    case InvoiceState.Pending:
      return "Pending";
    case (InvoiceState.Processing, InvoiceState.PaymentIntentCreated):
      return "Processing";
    case InvoiceState.Paid:
      return "Paid";
    case InvoiceState.ReadyToPay:
      return "Awaiting Payment";
    case InvoiceState.Refunded:
      return "Refunded";
    default:
      return uppercaseFirstLetter(state.toLowerCase());
  }
};

export const TPADescriptions: Record<JobState, string> = {
  [JobState.Approved]: "",
  [JobState.AwaitingPayment]: "",
  [JobState.Finalized]:
    "Once the job's complete and we've received payment from the client, we'll pay your creatives and your commission.",
  [JobState.Completed]:
    "Once the job's complete and we've received payment from the client, we'll pay your creatives and your commission.",
  [JobState.Expired]:
    "Unfortunately the booker did not confirm the job in time",
  [JobState.Cancelled]: "This job has been cancelled.",
  [JobState.Rejected]: "This job has been rejected",
  [JobState.PendingApproval]: "",
};

export const jobStateCard = [
  {
    state: JobState.PendingApproval,
    title: "Pending approval",
    icon: "hourGlass" as AvailableIcon,
    iconColor: "orange" as keyof typeof tokens.colors,
    color: jobStates.PENDING_APPROVAL,
    description:
      "This job is awaiting approval. What action would you like to take?",
  },
  {
    state: JobState.Approved,
    title: "Approved by contact",
    icon: "checkCircle" as AvailableIcon,
    iconColor: "purple" as keyof typeof tokens.colors,
    color: jobStates.APPROVED,
    description:
      "This job is pending confirmation from the booker. You may also confirm this job on behalf of the booker",
  },
  {
    state: JobState.AwaitingPayment,
    title: "Awaiting Payment",
    icon: "hourGlass" as AvailableIcon,
    iconColor: "turquoiseDark" as keyof typeof tokens.colors,
    color: jobStates.AWAITING_PAYMENT,
    description: "This job is awaiting payment from the booker. ",
  },
];
export const jobProgress = [
  {
    state: "JOB_SUBMITTED",
    title: "Job submitted",
    color: "black",
  },
  {
    state: JobState.PendingApproval,
    title: "Pending approval",
    description:
      "We’re still approving your job. This normally takes an hour or so but can be a lot quicker. Not heard from us? Please check your spam folder.",
    color: jobStates.PENDING_APPROVAL,
  },
  {
    state: JobState.Approved,
    title: "Talent responding",
    subtitle: "Average wait: 20 hours",
    description:
      "Your shortlisted creatives are still responding. Email them below to start a conversation.",
    color: jobStates.APPROVED,
  },
  {
    state: JobState.AwaitingPayment,
    title: "Awaiting payment",
    description:
      "Your shortlist is confirmed, but your job isn't confirmed until you pay via your selected payment method.",
    color: jobStates.AWAITING_PAYMENT,
    subMessage:
      "Talent will be notified after payment has been successfully processed and you're all looped into a confirmation email thread.",
  },
  {
    state: JobState.Finalized,
    title: "Job confirmed",
    description:
      "This job has been confirmed. Now just make sure your models are all prepared for the job date!",
    color: jobStates.FINALIZED,
  },
];

export const otherJobStates = (date: Date) => [
  {
    state: JobState.Cancelled,
    title: "Cancelled",
    description: "This job has been cancelled.",
    color: jobStates.CANCELLED,
  },
  {
    state: JobState.Completed,
    title: "Completed",
    description: `This job has completed on ${date && formatDate(date)}`,
    color: jobStates.COMPLETED,
  },
  {
    state: JobState.Expired,
    title: "Expired",
    description: "Unfortunately the client did not confirm the job in time.",
    color: jobStates.EXPIRED,
  },
  {
    state: JobState.Rejected,
    title: "Rejected",
    description: "This job has been rejected.",
    color: jobStates.REJECTED,
  },
];

export const TalentVerticalText = {
  ["FASHION_MODEL"]: "Models",
  ["PHOTOGRAPHER"]: "Photographers",
  ["HMUA"]: "Hair & makeup artists",
  ["INFLUENCER"]: "Influencers",
  ["ALL"]: "All",
};

export const HowOurFeesWork = [
  {
    icon: "ecommerce" as AvailableIcon,
    title: "12% booking fee",
    description:
      "Covers talent management, job approval, any fee/usage negotiation, assisting with finding replacements if creatives drop out.",
  },
  {
    icon: "rebrandLogo" as AvailableIcon,
    title: "5% platform fee",
    description:
      "Payment for Contact’s technology — ease of finding talent, managing jobs in the dashboard, automated payments, etc.",
  },
  {
    icon: "additionalFee" as AvailableIcon,
    title: "3% transaction fee",
    description: "Covers transaction and bank fees for all payments.",
  },
];

export const TALENT_RESPONSE_ON_JOB: Record<
  string,
  Partial<Record<(typeof JobTalentState)[keyof typeof JobTalentState], string>>
> = {
  FPA: {
    [JobTalentState.Accepted]: "Accepted",
    [JobTalentState.Rejected]: "Rejected",
    [JobTalentState.Pending]: "Waiting for response",
    [JobTalentState.RejectedByBooker]: "Released",
  },
  TPA: {
    [JobTalentState.Accepted]: "Accepted",
    [JobTalentState.Rejected]: "Rejected",
    [JobTalentState.Pending]: "Response pending",
    [JobTalentState.RejectedByBooker]: "Released",
  },
};

export const TALENT_RESPONSE_ON_JOB_PENDING =
  "Will be notified when the job is approved";
